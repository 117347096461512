import reduceReducers from "reduce-reducers";

import {
  ADD_BUSINESS_PROPERTY_COMMIT,
  ADD_BUSINESS_PROPERTY_OFFLINE,
  ADD_CONSIGNMENT_OFFLINE,
  ADD_SALE_LOT_COMMIT,
  ADD_SALE_LOT_OFFLINE,
  ADD_TEMP_BUYER_WAY,
  ATTACHMENT,
  BUSINESS,
  INTEGRATION_BUSINESS,
  PATCH_CONSIGNMENT_OFFLINE,
  SALE_LOT,
} from "constants/actionTypes";

import {
  apiModelOfflineCommentReducer,
  apiModelOfflineCreateReducer,
  apiModelOfflineDeleteReducer,
  apiModelOfflineFetchReducer,
  apiModelOfflineUpdateReducer,
} from "lib/reducers";
import { deserializeBusiness } from "lib/serializers/businesses";
import { deserializeIntegrationBusiness } from "lib/serializers/integrationBusinesses";

const create = apiModelOfflineCreateReducer(BUSINESS, {
  deserializer: deserializeBusiness,
});

const deleteReducer = apiModelOfflineDeleteReducer(BUSINESS);

const fetch = apiModelOfflineFetchReducer(BUSINESS, {
  deserializer: deserializeBusiness,
  clearOnRequest: true,
});

const update = apiModelOfflineUpdateReducer(BUSINESS, {
  deserializer: deserializeBusiness,
});

const comment = apiModelOfflineCommentReducer(BUSINESS);

const createBuyerWayFromSaleLotChange = (state, saleLot) => {
  const businessId = saleLot.buyer_id;
  // TODO: Casing for a serialized buyer_way on a sale lot is different to the casing for a buyer way on the businessV2.
  const buyerWay = saleLot.buyer_way;
  if (businessId && buyerWay) {
    // Find any existing entries.
    const existingEntryIndex = state.byId[businessId].buyerWays.findIndex(
      existingBuyerWay => existingBuyerWay.name === buyerWay.name,
    );

    const newBuyerWays = [...state.byId[businessId].buyerWays];

    // It was found, and is showing - nothing to do here.
    if (existingEntryIndex >= 0) {
      // It was found.  If it's already showing, nothing to do.
      if (state.byId[businessId].buyerWays[existingEntryIndex].isShown) {
        return state;
      }
      // Otherwise flip it to shown .
      newBuyerWays[existingEntryIndex].isShown = true;
    } else {
      // add a new one.
      newBuyerWays.push({ ...buyerWay, isShown: true });
    }

    return {
      ...state,
      byId: {
        ...state.byId,
        [businessId]: {
          ...state.byId[businessId],
          buyerWays: newBuyerWays,
        },
      },
    };
  } else {
    return state;
  }
};
const updateBuyerWayFromSaleLotChange = (state, saleLot) => {
  const businessId = saleLot.buyer_id;
  // TODO: Casing for a serialized buyer_way on a sale lot is different to the casing for a buyer way on the businessV2.
  const buyerWay = saleLot.buyer_way;
  if (businessId && buyerWay) {
    // The buyer way may/will be in there with a temp id - go update it.
    const newBuyerWays = state.byId[businessId].buyerWays.map(
      existingBuyerWay => {
        if (existingBuyerWay.name === buyerWay.name) {
          // Overwrite the id if set, otherwise keep all info the same.
          return {
            ...existingBuyerWay,
            ...buyerWay,
          };
        } else {
          return existingBuyerWay;
        }
      },
    );

    return {
      ...state,
      byId: {
        ...state.byId,
        [businessId]: {
          ...state.byId[businessId],
          buyerWays: newBuyerWays,
        },
      },
    };
  } else {
    return state;
  }
};

function buyerWayEventReducer(state, action) {
  switch (action.type) {
    // A buyer way added from the auction screen gets submitted with the sale lot.
    case ADD_TEMP_BUYER_WAY: {
      const { buyerId, buyerWay } = action;
      return {
        ...state,
        byId: {
          ...state.byId,
          [buyerId]: {
            ...state.byId[buyerId],
            buyerWays: [
              ...state.byId[buyerId].buyerWays,
              { ...buyerWay, isShown: true },
            ],
          },
        },
      };
    }

    // A buyer way can be added via submitting a sale lot - add it to the business straight away (we'll likely
    // get a subsequent pusher message)
    case ADD_SALE_LOT_OFFLINE:
    case SALE_LOT.UPDATE.REQUEST: {
      // The patch blob contains data on UPDATE, which is in payload on ADD.  They should be the same shape, though.
      const saleLot = action.payload.patch || action.payload || {};
      return createBuyerWayFromSaleLotChange(state, saleLot);
    }

    case SALE_LOT.UPDATE_BULK.REQUEST: {
      return action.payload.reduce((nextState, saleLot) => {
        nextState = createBuyerWayFromSaleLotChange(nextState, saleLot);
        return nextState;
      }, state);
    }

    case SALE_LOT.UPDATE_BULK.SUCCESS: {
      return action.payload.reduce((nextState, saleLot) => {
        nextState = updateBuyerWayFromSaleLotChange(nextState, saleLot);
        return nextState;
      }, state);
    }

    case ADD_SALE_LOT_COMMIT:
    case SALE_LOT.UPDATE.SUCCESS: {
      return updateBuyerWayFromSaleLotChange(state, action.payload);
    }
    default:
      return state;
  }
}

function consignmentEventReducer(state, action) {
  switch (action.type) {
    // When adding or updating a consignment with a branch, that may get set on this business.
    case ADD_CONSIGNMENT_OFFLINE:
    case PATCH_CONSIGNMENT_OFFLINE: {
      const { branch_id, vendor_id } = action.payload;
      if (
        branch_id &&
        state.byId[vendor_id] &&
        state.byId[vendor_id].branchId !== branch_id
      ) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [vendor_id]: {
              ...state.byId[vendor_id],
              branchId: branch_id,
            },
          },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

function attachmentEventReducer(state, action) {
  switch (action.type) {
    // When adding or updating an attachment with a branch, that may get set on this business.
    case ATTACHMENT.UPDATE.REQUEST: {
      const { vendor } = action;
      const { branch_id } = action.attachment;

      if (
        vendor &&
        branch_id &&
        state.byId[vendor.id] &&
        state.byId[vendor.id].branch_id !== branch_id
      ) {
        return {
          ...state,
          byId: {
            ...state.byId,
            [vendor.id]: {
              ...state.byId[vendor.id],
              branch_id,
            },
          },
        };
      } else {
        return state;
      }
    }
    default:
      return state;
  }
}

function propertyEventReducer(state, action) {
  switch (action.type) {
    // A buyer way added from the auction screen gets submitted with the sale lot.
    case ADD_BUSINESS_PROPERTY_OFFLINE: {
      const { meta, payload } = action;
      const { businessId, tempId } = meta;
      const { name } = payload;
      return {
        ...state,
        byId: {
          ...state.byId,
          [businessId]: {
            ...state.byId[businessId],
            properties: [
              ...state.byId[businessId].properties,
              { id: tempId, name, isShown: true },
            ],
          },
        },
      };
    }
    case ADD_BUSINESS_PROPERTY_COMMIT: {
      // More or less the same as add property, but deducping.
      const { meta, payload } = action;
      const { businessId, tempId } = meta;
      // Remove the temp one we had
      const updatedProperties = state.byId[businessId].properties.filter(
        property => property.id !== tempId,
      );
      // And add the committed version.
      updatedProperties.push({
        isShown: true,
        id: payload.id,
        PIC: payload.PIC,
      });

      return {
        ...state,
        byId: {
          ...state.byId,
          [businessId]: {
            ...state.byId[businessId],
            properties: updatedProperties,
          },
        },
      };
    }
    default:
      return state;
  }
}

function integrationBusinessReducer(state, action) {
  switch (action.type) {
    case INTEGRATION_BUSINESS.UNLINK_FROM_BUSINESS.ACTION: {
      const { businessId, id: integrationBusinessId } = action;

      const updatedIntegrationBusinesses = state.byId[
        businessId
      ].integrationBusinesses.filter(
        integrationBusiness => integrationBusiness.id !== integrationBusinessId,
      );
      return {
        ...state,
        byId: {
          ...state.byId,
          [businessId]: {
            ...state.byId[businessId],
            integrationBusinesses: updatedIntegrationBusinesses,
          },
        },
      };
    }
    case INTEGRATION_BUSINESS.UPDATE.REQUEST: {
      const updatedIntegrationBusiness = deserializeIntegrationBusiness(
        action.payload,
      );
      if (updatedIntegrationBusiness.deploymentBusinessId == null) {
        return state;
      }

      // Find all of the Specific Businesses which have been mapped (by Deployment Business Id) to the Integration Business
      const affectedBusinesses = Object.values(state.byId).filter(
        specificBusiness =>
          updatedIntegrationBusiness.deploymentBusinessId ===
          specificBusiness.deploymentBusinessId,
      );

      const updatedBusinesses = affectedBusinesses.reduce((acc, business) => {
        acc[business.id] = {
          ...business,
          integrationBusinesses: [
            // If it exists, remove the existing, matching, integration business
            ...business.integrationBusinesses.filter(
              integrationBusiness =>
                integrationBusiness.id !== updatedIntegrationBusiness.id ||
                integrationBusiness.type !== updatedIntegrationBusiness.type,
            ),
            // Add a new copy of the Integration Business with the latest data
            {
              deploymentBusinessId:
                updatedIntegrationBusiness.deploymentBusinessId,
              integrationId: updatedIntegrationBusiness.deploymentBusinessId,
              lastSuccessfulPull: updatedIntegrationBusiness.lastSuccessfulPull,
              name: updatedIntegrationBusiness.name,
              type: updatedIntegrationBusiness.type,
            },
          ],
        };
        return acc;
      }, {});

      return {
        ...state,
        byId: {
          ...state.byId,
          ...updatedBusinesses,
        },
      };
    }
    default:
      return state;
  }
}

const businessV2Reducer = reduceReducers(
  create,
  comment,
  deleteReducer,
  fetch,
  update,
  buyerWayEventReducer,
  consignmentEventReducer,
  attachmentEventReducer,
  propertyEventReducer,
  integrationBusinessReducer,
);

export default businessV2Reducer;
