import React, { useEffect, useRef, useState } from "react";

import { useDebounce } from "@react-hook/debounce";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import { Column, Row } from "components/Layout/index";

import { getSetting } from "selectors";

import { useMountEffect } from "hooks";

const VerticalResizer = styled(Row)`
  resize: vertical;
  overflow: auto;
  height: ${({ size }) => size};
`;

const HorizontalResizer = styled(Column)`
  resize: horizontal;
  overflow: auto;
  width: ${({ size }) => size};
`;

export const ResizableWrapper = ({
  children,
  isVertical,
  settingKey,
  defaultInitialSize = "50%",
}) => {
  const divRef = useRef(null);

  const initialSize = useSelector(getSetting(settingKey)) || defaultInitialSize;
  const [size, setSize] = useState(initialSize);

  const [finalSize, setFinalSize] = useDebounce(initialSize, 1000);

  const dispatch = useDispatch();

  useEffect(() => {
    // When final size is stable, stick it in settings.
    if (settingKey) {
      dispatch(setSetting(settingKey, finalSize));
    }
  }, [finalSize, settingKey, dispatch]);

  useMountEffect(() => {
    if (divRef.current) {
      const resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          const valueToSet = isVertical
            ? entry.contentRect.height
            : entry.contentRect.width;
          setSize(`${valueToSet}px`);
          setFinalSize(`${valueToSet}px`);
        }
      });

      resizeObserver.observe(divRef.current);

      return () => {
        if (divRef.current) {
          resizeObserver.unobserve(divRef.current);
        }
      };
    }
  });

  const WrapperComponent = isVertical ? VerticalResizer : HorizontalResizer;
  return (
    <WrapperComponent ref={divRef} size={size}>
      {children}
    </WrapperComponent>
  );
};
