import React from "react";

import {
  faBookDead,
  faCrosshairs,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import styled from "styled-components/macro";

import Badge from "components/Badge";
import { IconTextButton } from "components/Button";
import NestedCard from "components/Card/NestedCard";
import { ChipBag, MoneyChip, WeightChip } from "components/Chip";
import { PlainCollapse } from "components/Collapse";
import { UnresolvedCommentIcon } from "components/Comments/Icon";
import { PreventPropagation } from "components/Events";
import { RightActionColumn } from "components/GroupedCard";
import { OpenIndicator } from "components/Icons/CollapseIcon";
import { Column, Row } from "components/Layout";
import LotCard from "components/LotCard";
import { CurrentSaleReadOnly } from "components/ReadOnly";
import ScannedCount from "components/ScannedCount";
import { BoldText, StatusText, Subtitle } from "components/Text";

import { PenTypes } from "constants/auctionPens";
import { SaleLotModalSection } from "constants/navigation";
import {
  ConsignmentPermissions,
  SaleyardPermissions,
} from "constants/permissions";
import { cards } from "constants/sale";

import { ConsignmentCardActionOptions } from "containers/ActionOptions";
import {
  ForHooks,
  ForLivestockAuction,
  ForLivestockSale,
  ForNotHooksOrPaddock,
  ForSaleyardAuction,
} from "containers/ForSaleType";
import { TranslatedSaleTypeLabel } from "containers/TranslatedSaleTypeLabel";

import { getCompareSaleLots } from "lib/auctionPens";
import {
  getEditSaleLotHashRoute,
  getScanRoute,
  openKillSheetHashModal,
} from "lib/navigation";

import {
  getConsignmentById,
  getCurrentSaleyard,
  getHasWriteAccessInCurrentSale,
  getUnresolvedSaleLotCommentCountByConsignmentId,
} from "selectors";

import { useToggle } from "hooks";
import { useHasPermission } from "hooks/useHasPermission";

const FlexLeft = styled.div`
  display: flex;
  justify-content: space-between;
  width: 40%;
  min-width: 190px;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    width: 100%;
    margin-top: 10px;
  }
`;

const InfoContainer = styled.div`
  width: 50%;
  margin-right: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints[0]}px) {
    width: 100%;
    min-width: 50%;
    margin-bottom: 10px;
  }
`;

const AlignRight = styled.div`
  margin-left: auto;
  display: flex;
  width: ${({ width }) => width || "200px"};
  justify-content: space-between;
`;

const HeadCountNumber = styled.span`
  font-size: 18px;
  margin-right: 10px;
  font-weight: bold;
`;

const ExceptionsRow = styled.div`
  padding-top: 10px;
`;

const ConsignmentCard = ({
  consignment,
  vendorReportUrl,
  totalConsignmentsQuantity,
}) => {
  const match = useRouteMatch();
  const basePath = match.url;

  const [isOpen, toggleIsOpen] = useToggle(totalConsignmentsQuantity === 1);

  const hasWriteAccessInCurrentSale = useSelector(
    getHasWriteAccessInCurrentSale,
  );

  const openCard = event => {
    toggleIsOpen();
    event.stopPropagation();
  };

  const {
    id,
    consignmentScannedCount,
    consignmentScannedPercentage,
    status,
    quantity,
    quantityDrafted,
    quantityUndrafted,
    receivingPen,
    livestock_agency_code,
    receivingReference,
    vendorNumber,
    scannedCount,
    scannedStatus,
    scannedPercentage,
    exceptions,
    saleLots,
    isAllPenned,
    averageWeight,
    pricePerHeadIncSkins,
  } = consignment;

  const unresolvedSaleLotComments = useSelector(
    getUnresolvedSaleLotCommentCountByConsignmentId(id),
  );

  saleLots.sort(getCompareSaleLots("startPen"));

  const scanUrl = `#${getScanRoute(
    id,
    null,
    false,
    null,
    window.location.pathname,
    null,
    null,
    null,
    PenTypes.SELLING,
  )}`;
  const resumeScanUrl = `#${getScanRoute(
    id,
    null,
    true,
    null,
    window.location.pathname,
    null,
    null,
    null,
    PenTypes.SELLING,
  )}`;

  const hasAddSaleLotPermission = useHasPermission(
    getConsignmentById(id),
    ConsignmentPermissions.canAddSaleLot,
  );

  const hasReceivalLotPermission = useHasPermission(
    getCurrentSaleyard,
    SaleyardPermissions.featureReceivalLots,
  );

  return (
    <NestedCard data-tour="consignmentNest" slim onClick={openCard}>
      <Row full>
        <Column full style={{ marginRight: 12 }}>
          <Row justifyBetween flexWrap style={{ paddingBottom: 10 }} full>
            <FlexLeft>
              <InfoContainer data-tour={status}>
                <Subtitle>
                  <TranslatedSaleTypeLabel label="Consignment" />
                </Subtitle>
                <Row alignCenter>
                  <BoldText>
                    <Badge color={status} uppercase>
                      {status}
                    </Badge>
                  </BoldText>
                  <UnresolvedCommentIcon
                    unresolvedCommentCount={unresolvedSaleLotComments}
                    horizontalPadding={1}
                    size="2x"
                  />
                </Row>
              </InfoContainer>

              {/* don't show receival pen info when receival lot permissions are enabled */}
              {!hasReceivalLotPermission && (
                <ForSaleyardAuction>
                  <InfoContainer data-tour="recPen">
                    <Subtitle>Receiving pen</Subtitle>
                    <BoldText
                      style={{ textDecoration: isAllPenned && "line-through" }}
                    >
                      {receivingPen || "-"}
                    </BoldText>
                  </InfoContainer>
                </ForSaleyardAuction>
              )}
            </FlexLeft>
            <AlignRight width="200px">
              <div>
                <Subtitle>Hd</Subtitle>
                <BoldText>
                  <HeadCountNumber data-tour="headCount">
                    {quantity}
                  </HeadCountNumber>
                  {quantityUndrafted !== 0 && (
                    <Badge color="warning">
                      {quantityUndrafted < 0 && "+"}
                      {-quantityUndrafted}
                    </Badge>
                  )}
                </BoldText>
              </div>
              <ForLivestockSale>
                <div>
                  <Subtitle align="end">EIDs</Subtitle>
                  <ScannedCount
                    count={consignmentScannedCount}
                    percentage={consignmentScannedPercentage}
                    prefix="C: "
                    saleLotScansCount={scannedCount}
                    status={scannedStatus}
                  />
                  <ScannedCount
                    count={scannedCount}
                    percentage={scannedPercentage}
                    prefix="L: "
                    status={scannedStatus}
                  />
                </div>
              </ForLivestockSale>
            </AlignRight>
          </Row>
          <Row
            justifyBetween
            flexWrap
            style={{ paddingRight: 44, paddingBottom: 10 }}
          >
            <FlexLeft>
              <InfoContainer data-tour="recRef">
                <Subtitle>Ref</Subtitle>
                <BoldText>{receivingReference || "-"}</BoldText>
              </InfoContainer>
              <ForLivestockAuction>
                <InfoContainer data-tour="venNum">
                  <Subtitle>Ven. #</Subtitle>
                  <BoldText>
                    {livestock_agency_code}
                    {vendorNumber || "-"}
                  </BoldText>{" "}
                </InfoContainer>
              </ForLivestockAuction>
            </FlexLeft>
          </Row>
          <ForHooks>
            {(averageWeight > 0 || pricePerHeadIncSkins > 0) && (
              <Row>
                <ChipBag>
                  <WeightChip>Avg Weight {averageWeight} Kg</WeightChip>
                  <MoneyChip>$/Hd {pricePerHeadIncSkins}</MoneyChip>
                </ChipBag>
              </Row>
            )}
          </ForHooks>
          <ForNotHooksOrPaddock>
            <ExceptionsRow>
              {exceptions.map(e => (
                <StatusText status="error" key={e}>
                  {e}
                </StatusText>
              ))}
            </ExceptionsRow>
          </ForNotHooksOrPaddock>
        </Column>
        <RightActionColumn>
          <ConsignmentCardActionOptions
            basePath={basePath}
            consignment={consignment}
            mainObject={{ id, resumeScanUrl }}
            parent={cards.CONSIGNMENT_CARD}
            saleLots={saleLots}
            vendorReportUrl={vendorReportUrl}
            readOnly={!hasWriteAccessInCurrentSale}
          />
          <OpenIndicator isOpen={isOpen} />
        </RightActionColumn>
      </Row>

      <NestedCard bordered lightBackground>
        <Row justifyBetween flexWrap>
          <FlexLeft style={{ minWidth: 225 }}>
            <InfoContainer data-tour="notDrafted">
              <ForNotHooksOrPaddock>
                <Subtitle>Not drafted</Subtitle>
                <HeadCountNumber>{quantityUndrafted}</HeadCountNumber>
              </ForNotHooksOrPaddock>
            </InfoContainer>
            <InfoContainer data-tour="draftPen">
              <ForNotHooksOrPaddock>
                <Subtitle>Drafted/Penned</Subtitle>
                <BoldText>{quantityDrafted}</BoldText>
              </ForNotHooksOrPaddock>
            </InfoContainer>
          </FlexLeft>
          <ForLivestockSale>
            <AlignRight data-tour="iconButtons" width="auto">
              <CurrentSaleReadOnly>
                <IconTextButton
                  as={Link}
                  icon={faCrosshairs}
                  to={scanUrl}
                  data-tour="scanButton"
                >
                  Scan
                </IconTextButton>
                <IconTextButton
                  data-tour="draft"
                  as={hasAddSaleLotPermission ? Link : undefined}
                  icon={hasAddSaleLotPermission ? faPlus : faLock}
                  onClick={openCard}
                  disabled={!hasAddSaleLotPermission}
                  to={`#${getEditSaleLotHashRoute(
                    null,
                    SaleLotModalSection.GENERAL,
                    null,
                    consignment.id,
                    null,
                  )}`}
                >
                  Draft new Sale Lot
                </IconTextButton>
              </CurrentSaleReadOnly>

              <ForHooks>
                <IconTextButton
                  icon={faBookDead}
                  style={{ zIndex: 100 }}
                  onClick={() =>
                    openKillSheetHashModal(consignment.id, window.location.hash)
                  }
                >
                  Kill Sheet
                </IconTextButton>
              </ForHooks>
            </AlignRight>
          </ForLivestockSale>
        </Row>
      </NestedCard>

      <PlainCollapse isOpen={isOpen}>
        <PreventPropagation>
          {saleLots.map(lot => (
            <LotCard
              key={lot.id}
              cardType={cards.SALE_LOT_CARD}
              saleLot={lot}
              basePath={basePath}
              consignmentId={id}
              showScanning
              showPen
            />
          ))}
        </PreventPropagation>
      </PlainCollapse>
    </NestedCard>
  );
};

ConsignmentCard.propTypes = {
  consignment: PropTypes.object.isRequired,
  totalConsignmentsQuantity: PropTypes.number.isRequired,
  vendorReportUrl: PropTypes.string,
};

export default ConsignmentCard;
