import queryString from "query-string";

import { offlineActionCreator, urlCheckAndAddIdAndAction } from "actions/lib";

import {
  PROPERTY,
  ADD_BUSINESS_PROPERTY_COMMIT,
  ADD_BUSINESS_PROPERTY_ROLLBACK,
  ADD_BUSINESS_PROPERTY_OFFLINE,
} from "constants/actionTypes";

import { serializeProperty } from "lib/serializers/properties";

export const requestPropertyERPCheck = propertyId => ({
  type: PROPERTY.ERP_QUERY.REQUEST,
  meta: {
    propertyId,
    offline: {
      effect: {
        url: `/v2/properties/${propertyId}/requery_erp/`,
        method: "POST",
        body: "",
      },
      commit: {
        type: PROPERTY.ERP_QUERY.SUCCESS,
      },
      rollback: {
        type: PROPERTY.ERP_QUERY.FAILURE,
      },
    },
  },
});

export function addPropertyToBusiness(property, businessId) {
  const { PIC, name, id } = property;
  const payload = {
    PIC,
    name,
    id,
  };

  const meta = {
    payload,
    businessId,
    tempId: id,
  };

  return {
    type: ADD_BUSINESS_PROPERTY_OFFLINE,
    payload,
    meta: {
      tempId: id,
      businessId,
      offline: {
        effect: {
          url: `/v2/businesses/${businessId}/properties/`,
          method: "POST",
          body: JSON.stringify(payload),
        },
        commit: {
          type: ADD_BUSINESS_PROPERTY_COMMIT,
          meta,
        },
        rollback: {
          type: ADD_BUSINESS_PROPERTY_ROLLBACK,
          meta,
        },
      },
    },
  };
}

const urlCreator = ({ changesSince, livestockSaleId, id, action } = {}) =>
  queryString.stringifyUrl({
    url: urlCheckAndAddIdAndAction("/v2/properties/", id, action),
    query: {
      livestock_sale_id: livestockSaleId,
      changesSince,
    },
  });

export const PropertyAction = offlineActionCreator(
  PROPERTY,
  serializeProperty,
  urlCreator,
  true,
);
