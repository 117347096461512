import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  ControlledLifeCycleInput,
  DecimalNumberInputProps,
  Label,
  SelectField,
  withNamespace,
} from "components/Form/FormikControls";
import { useSelector } from "react-redux";
import {
  getBillingDocumentById,
  selectLedgerAccountOptionsForLedgerEntryForm,
} from "selectors";
import { Column } from "components/Layout";
import { useFieldValue } from "hooks";
import { getDollarPriceStringFromCents } from "lib";

interface FormProps {
  billingDocumentId: string;
  namespace?: string;
  readOnly?: boolean;
}

export interface FormValues {
  glCode: string | null;
  totalAmountCents: number;
}

export function RetainProceedsForm(props: FormProps): React.JSX.Element {
  const { billingDocumentId, namespace: ns = "", readOnly = false } = props;

  const billingDocument = useSelector<any, BillingDocument>(
    getBillingDocumentById(billingDocumentId),
  );

  const ledgerAccountOptions = useSelector(
    selectLedgerAccountOptionsForLedgerEntryForm as (
      any,
    ) => SelectFieldOption[],
  );
  const retainedAmountCents =
    useFieldValue(withNamespace(ns, "totalAmountCents")) || 0;

  const initialAmountOwingCents = Math.round(
    (billingDocument.totalAmountDollars || 0) * 100,
  );

  const currentAmountOwingCents =
    initialAmountOwingCents - (billingDocument.totalPaidCents || 0);

  const remainingOwingCents = currentAmountOwingCents - retainedAmountCents;

  return (
    <>
      <Grid item xs={6}>
        <Column>
          <Label>Initial Amount Owing</Label>
          {`$${getDollarPriceStringFromCents(initialAmountOwingCents)}`}
        </Column>
      </Grid>
      <Grid item xs={6}>
        <ControlledLifeCycleInput
          beforeSymbol="$"
          compareInternalValues={DecimalNumberInputProps.compareInternalValues}
          decimal
          decimalPlaces={2}
          disabled={readOnly}
          fieldDecimalPlaces={0}
          fieldMultiplier={100}
          formatFieldValue={DecimalNumberInputProps.formatFieldValue}
          formatInternalValue={DecimalNumberInputProps.formatInternalValue}
          label="Proceeds to retain"
          multiplier={0.01}
          name={withNamespace(ns, "totalAmountCents")}
          parseInputValue={DecimalNumberInputProps.parseInputValue}
          parseInternalValue={DecimalNumberInputProps.parseInternalValue}
          required
          type="number"
          validateInputValue={DecimalNumberInputProps.validateInputValue}
        />
      </Grid>

      <Grid item xs={6}>
        <Column>
          <Label>Amount owing after retaining proceeds</Label>
          {`$${getDollarPriceStringFromCents(remainingOwingCents)}`}
        </Column>
      </Grid>
      <Grid item xs={6}>
        <SelectField
          label="GL Code"
          disabled={readOnly}
          name={withNamespace(ns, "glCode")}
          required
          options={ledgerAccountOptions}
          menuPortalTarget={document.body}
        />
      </Grid>
    </>
  );
}
