import React, { useMemo } from "react";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import {
  bulkCreateSellPossessions,
  bulkCreateTakePossessions,
  setModalContext,
} from "actions";

import { AgGridPreview } from "components/AgGrid/AgGridPreview";
import { CollapseTableWrapper } from "components/AgGrid/TableWrapper";
import { Button, SecondaryButton } from "components/Form";
import { Column, Row } from "components/Layout";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { AgGridTables } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import { NLISFileTypeHeadings, NLISFileTypes } from "constants/nlis";

import { pluralize } from "lib/pluralize";

import { getContextByModalType, scanList } from "selectors";

import { confirmationColumnDefs as sellColumnDefs } from "./NLISSellFiles";
import { confirmationColumnDefs as takeColumnDefs } from "./NLISTakeFiles";

const SupportedTransferTypes = [
  NLISFileTypes.NLISTakeFile,
  NLISFileTypes.NLISSellFile,
];

const WarningIcon = styled(FontAwesomeIcon).attrs(() => ({
  icon: faExclamationTriangle,
  size: "3x",
}))`
  color: ${({ theme }) => theme.colors.warning};
`;

export function ConfirmManualTransfer(props) {
  const { onClose } = props;

  const dispatch = useDispatch();

  const { eids = [], transferType } =
    useSelector(getContextByModalType(ModalTypes.ForceTransfer)) || {};
  const allScansRowData = useSelector(scanList);
  const rowData = useMemo(
    () =>
      eids.map(eid =>
        allScansRowData.find(rowData => rowData.scan.EID === eid),
      ),
    [allScansRowData, eids],
  );

  function onClickTransfer() {
    if (transferType === NLISFileTypes.NLISTakeFile) {
      dispatch(bulkCreateTakePossessions(eids));
    } else if (transferType === NLISFileTypes.NLISSellFile) {
      dispatch(bulkCreateSellPossessions(eids));
    }
    // explicitly clear the EIDs so that users can't just re-submit them
    dispatch(setModalContext(ModalTypes.ForceTransfer, {}));
    onClose();
  }

  const transferName = NLISFileTypeHeadings[transferType] || transferType;

  let transferWarningMessage = `Submitting a manual ${transferName} transfer is not yet supported.`;
  let columnDefs = [];

  if (transferType === NLISFileTypes.NLISTakeFile) {
    transferWarningMessage = `Only perform this action if you are sure the ${pluralize("Scan", eids.length)} ${pluralize("is", eids.length)} on the Vendors PIC according to the NLIS`;
    columnDefs = takeColumnDefs;
  } else if (transferType === NLISFileTypes.NLISSellFile) {
    transferWarningMessage = `Only perform this action if you are sure the ${pluralize("Scan", eids.length)} ${pluralize("is", eids.length)} on the Saleyard Holdings according to the NLIS`;
    columnDefs = sellColumnDefs;
  }

  if (!SupportedTransferTypes.includes(transferType) || !transferType) {
    return (
      <Dialog open onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle onClose={onClose}>Manual NLIS Transfer</DialogTitle>
        <DialogContent>
          <Row>
            <div>
              {!transferType
                ? "You have specified an invalid transfer type"
                : transferWarningMessage}
            </div>
          </Row>
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={onClose}>Close</SecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog open onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle onClose={onClose}>
        Submit a manual {transferName} for the the following Scans
      </DialogTitle>
      <DialogContent>
        <Row>
          <Column className="p-2">
            <WarningIcon />
          </Column>

          <div>
            This will send a {transferName} request to the NLIS for the selected{" "}
            {pluralize("Scan", eids.length)}.<br />
            It will ignore the current and any previous Take, Sell, or Rollback
            actions, which may result in errors from NLIS, or loss of a LT
            status.
            <br />
            {transferWarningMessage}
          </div>
        </Row>

        <CollapseTableWrapper>
          <AgGridPreview
            rowData={rowData}
            columnDefs={columnDefs}
            tableName={AgGridTables.NLIS_CONFIRM_TRANSFER}
          />
        </CollapseTableWrapper>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <Button onClick={onClickTransfer}>Transfer</Button>
      </DialogActions>
    </Dialog>
  );
}
