import { uniq } from "lodash";
import queryString from "query-string";
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLeading,
} from "redux-saga/effects";
import { v4 as uuidv4 } from "uuid";

import {
  addSaleLotWithPens,
  addScansFromBuffer,
  addUnassignedScans,
  bulkMoveScans,
  bulkMoveScansOffline,
  deleteSaleLotNoLongerExists,
  deleteUnassignedScans,
  patchSaleLot,
  receiveScans,
  receiveScansChanges,
  requestScansChanges,
  requestScansError,
  resendScan,
  startDraft,
  uploadScansAction,
  uploadScansAndDraftingInformation,
} from "actions";

import {
  deleteSaleLotScansOffline,
  deleteScan,
  DraftingInformationAction,
  SaleLotAction,
  uploadScans,
} from "actions/offline";

import {
  ADD_SCAN_FROM_SCANNER,
  ADD_SCANS_FROM_BUFFER,
  BULK_MOVE_SCANS,
  BULK_MOVE_SCANS_COMMIT,
  BULK_MOVE_SCANS_ROLLBACK,
  CONFIRM_START_DRAFT,
  DELETE_ALL_SCANS_FROM_UNASSIGNED,
  DELETE_SALE_LOT_SCANS_ACTION,
  DELETE_SALE_LOT_SCANS_COMMIT,
  DELETE_SALE_LOT_SCANS_ROLLBACK,
  DRAFTING_INFORMATION,
  GENERIC_UPLOAD_SCANS_AND_DRAFTING_INFORMATION,
  GET_SCANS,
  GET_SCANS_CHANGES,
  PEN_SCAN_LOT,
  RECEIVAL_LOT,
  SCAN,
  SINGLE_WEIGH,
  START_DRAFT,
  UPDATE_SCAN_NLIS_COMMIT,
  UPDATE_SCAN_NLIS_OFFLINE,
  UPDATE_SCAN_NLIS_ROLLBACK,
  UPLOAD_DRAFT,
  UPLOAD_NLIS_ID_COMMIT,
  UPLOAD_NLIS_ID_ROLLBACK,
  UPLOAD_SCANS_ACTION,
  UPLOAD_SCANS_AND_DRAFTING_INFORMATION,
  UPLOAD_SCANS_COMMIT,
  UPLOAD_SCANS_ROLLBACK,
} from "constants/actionTypes";
import { ModalTypes } from "constants/navigation";
import { UNALLOCATED } from "constants/scanner";

import { calculateTotalPriceCents, calculateUnitPrice } from "lib";

import { confirmDialogWithSubAction } from "lib/confirmDialog";
import { getSaleUrl, getSaleyardName, openPenScanning } from "lib/navigation";
import { getDraftInformationFieldsFromScan } from "lib/scans";
import toast from "lib/toast";

import {
  currentSaleSelector,
  getConnectedDeviceId,
  getDeviceLookup,
  getDraftingInformationIdByHash,
  getGroupedUnassignedScans,
  getSaleLots,
  getScans,
  getUnassignedScans,
  getUnassignedScanSummary,
  selectSaleLotIdByEidLookup,
  selectScansBySaleLotIdLookup,
} from "selectors";

import { api } from "./api";
import { onCreateSaleLotAction } from "./saleLots";

function* uploadDraft(action) {
  // TODO - this function is currently orphanned and not used, but may come back in the weighbridge.
  // so remains in place.  It is NOT tested with the uploadScansAndDraftingInformation changes.
  const { draftName, saleLotId } = action;
  const state = yield select();

  const scansToUpload = getGroupedUnassignedScans(state)[draftName];

  const deviceLookup = getDeviceLookup(state);

  const uploadableScans = scansToUpload.map(scan => ({
    EID: scan.EID,
    created: scan.created,
    draft_name: scan.draftName,
    device_id: scan.deviceId,
    device_name: scan.deviceName || deviceLookup?.[scan.deviceId]?.name || "",
  }));

  yield put(uploadScansAndDraftingInformation(uploadableScans, saleLotId));
}

function* fetchScans(action) {
  try {
    const { sale } = action;
    if (sale) {
      const saleUrl = getSaleUrl(sale);
      const saleScansEndpoint = `${saleUrl}/scans/`;
      const scansResponsePromise = yield call(api.get, saleScansEndpoint);
      const scansResponse = yield scansResponsePromise;
      const scans = yield scansResponse.JSON;
      const { lastModifiedTimestamp, cacheHit } = scansResponse;
      yield put(
        receiveScans(sale.livestocksale_id, scans, lastModifiedTimestamp),
      );

      // If this was a cached result, immediately trigger a chance since.
      if (cacheHit) {
        yield put(requestScansChanges(sale));
      }
    }
  } catch (e) {
    yield call(api.handleFetchError, e, "EID scan data", action);
    yield put(requestScansError(e.statusText));
  }
}

function* fetchScansChanges(action) {
  try {
    const { sale } = action;
    if (sale) {
      const state = yield select();
      const changesSince = state.scanners.lastModifiedTimestamp;
      const saleUrl = getSaleUrl(sale);
      const saleScansEndpoint = `${saleUrl}/scans/?changesSince=${changesSince}`;
      const scansResponsePromise = yield call(api.get, saleScansEndpoint);
      const scansResponse = yield scansResponsePromise;
      const scans = yield scansResponse.JSON;
      const { lastModifiedTimestamp } = scansResponse;

      yield put(
        receiveScansChanges(
          sale.livestocksale_id,
          scans,
          lastModifiedTimestamp,
        ),
      );
    }
  } catch (e) {
    yield call(api.handleFetchError, e, "EID scan update", action);
    yield put(requestScansError(e.statusText));
  }
}

export function* fetchCurrentSaleScansChanges() {
  const state = yield select();
  const sale = currentSaleSelector(state);

  yield fetchScansChanges({ sale });
}

const uploadScansSuccess = action => {
  const { payload } = action;
  const with_sale_lot = payload.scans.filter(s => s.sale_lot_id).length;
  const without_sale_lot = payload.scans.length - with_sale_lot;
  if (with_sale_lot > 0) {
    toast.success(`${with_sale_lot} scan(s) were added to a consignment.`);
  }
  if (without_sale_lot > 0) {
    toast.success(
      `${without_sale_lot} scan(s) were added to the unallocated list.`,
    );
  }
  // If we got data back, great.  But there may be secondary errors, like
  // NLIS not responding.
  if (payload && payload.error) {
    toast.error(`${payload.error}`);
  }
};

/** *
 * Call this saga everytime a scan is added or removed from a salelot.
 */
function* recalculateSaleLotsWeight(recalculateWeightMap) {
  // recalculateWeightMap is an object keyed by scan EID, with a value of { from: saleLotId, to: saleLotId }
  const state = yield select();
  const scanLookup = getScans(state);
  const saleLotLookup = getSaleLots(state);

  // Build an object of { saleLotId: totalMassGrams }
  const newWeights = Object.entries(recalculateWeightMap).reduce(
    (acc, [eid, movement]) => {
      // Only update sale lot weights if the scan has a weight/is single weighed and is moving to a different lot.
      const scanWeight = scanLookup[eid]?.total_mass_grams || null;
      const isDestinationLotsEqual = movement.from === movement.to;
      const isMovingToSameSaleLot =
        (movement.from || movement.to) && isDestinationLotsEqual;

      if (scanWeight !== null && !isMovingToSameSaleLot) {
        // If we know about the salelot we are moving from already
        if (movement.from && movement.from !== UNALLOCATED) {
          if (acc[movement.from]) {
            acc[movement.from] -= scanWeight;
          } else {
            acc[movement.from] =
              saleLotLookup[movement.from].total_mass_grams - scanWeight;
          }
        }

        if (movement.to && movement.to !== UNALLOCATED) {
          // If we know about the salelot we are moving to already
          if (acc[movement.to]) {
            acc[movement.to] += scanWeight;
          } else {
            acc[movement.to] =
              saleLotLookup[movement.to].total_mass_grams + scanWeight;
          }
        }
      }

      return acc;
    },
    {},
  );

  yield all(
    Object.entries(newWeights).map(([saleLotId, totalMassGrams]) => {
      const saleLot = saleLotLookup[saleLotId];

      // Make sure we dont let the weight go negative.
      const nonNegativeTotalMassGrams = Math.max(0, totalMassGrams);

      const payload = {
        total_mass_grams: nonNegativeTotalMassGrams || 0,

        total_price_cents: calculateTotalPriceCents({
          ...saleLot,
          unitPrice: calculateUnitPrice(saleLot),
          total_mass_grams: nonNegativeTotalMassGrams || 0,
        }),
        id: saleLot.id,
      };
      return put(
        patchSaleLot(payload, {
          disabledToast: true,
          changeReason: "Adjusting weight due to single weighed scan moved.",
        }),
      );
    }),
  );
}

function* deleteSaleLotScansAction(action) {
  const { saleLotId, sale } = action;

  const state = yield select();
  const scans = selectScansBySaleLotIdLookup(state)[saleLotId] || [];

  // Recalculate the salelot weight and price
  const recalculateWeightMap = scans.reduce((map, scan) => {
    map[scan.EID] = {
      from: scan.sale_lot_id,
    };
    return map;
  }, {});

  // The order of these actions is important.
  yield call(recalculateSaleLotsWeight, recalculateWeightMap);
  yield put(deleteSaleLotScansOffline(saleLotId, sale));
}

export function* onUploadScansAction(action) {
  const { scans, saleLotId } = action;

  const state = yield select();
  const saleLotIdByEidLookup = selectSaleLotIdByEidLookup(state);

  const eidLinkedSaleLotIds = uniq(
    scans.map(scan => saleLotIdByEidLookup[scan.EID]),
  ).filter(Boolean);

  const firstEid = scans.length > 0 ? scans[0].EID : null;

  // Recalculate the salelot weight and price
  const recalculateWeightMap = scans.reduce((map, scan) => {
    const fromSaleLotId = saleLotIdByEidLookup[scan.EID] || null;
    if (fromSaleLotId === saleLotId) {
      return map;
    }
    map[scan.EID] = {
      from: fromSaleLotId,
      to: saleLotId,
    };
    return map;
  }, {});

  // if these scans are new to the system - sight this sale lot
  if (
    eidLinkedSaleLotIds.length === 0 &&
    firstEid &&
    saleLotId &&
    saleLotId !== UNALLOCATED
  ) {
    yield put(SaleLotAction.draftSighting(saleLotId, firstEid));
  }

  // The order of these actions is not important.
  yield call(recalculateSaleLotsWeight, recalculateWeightMap);

  yield put(uploadScans(scans, saleLotId));
}

function* deleteScanAction(action) {
  const { scan } = action;

  // Recalculate the salelot weight and price
  const recalculateWeightMap = { [scan.EID]: { from: scan.sale_lot_id } };
  yield call(recalculateSaleLotsWeight, recalculateWeightMap);
  yield put(deleteScan(scan));
}

function* onBulkMoveScans(action) {
  const { eids, saleLotId } = action;

  const state = yield select();
  const saleLotIdByEidLookup = selectSaleLotIdByEidLookup(state);

  const currentSaleLotMap = eids.reduce((map, eid) => {
    map[eid] = saleLotIdByEidLookup[eid];
    return map;
  }, {});
  yield put(bulkMoveScansOffline(eids, saleLotId, currentSaleLotMap));

  // Recalculate the salelot weight and price
  const recalculateWeightMap = eids.reduce((map, eid) => {
    map[eid] = {
      from: saleLotIdByEidLookup[eid],
      to: saleLotId,
    };
    return map;
  }, {});

  // The order of these actions is NOT important (the scans will still exist.).
  yield call(recalculateSaleLotsWeight, recalculateWeightMap);
}

const updateScansSuccess = action => {
  const {
    payload: { scans },
  } = action;
  toast.success(
    `Updated sale lot on ${scans.length} scan${scans.length !== 1 ? "s" : ""}.`,
  );
};

const updateNLISScansSuccess = action => {
  const {
    payload: { error, scans },
  } = action;
  if (scans.length) {
    toast.success(`${scans.length} scan(s) updated.`);
  }
  // If we got data back, great.  But there may be secondary errors, like
  // NLIS not responding.
  if (error) {
    toast.error(`${error}`);
  }
};

function* rollbackAddScansToSalelot(action) {
  const { meta } = action;
  const { scans, saleLotId, statusCode } = meta;

  if (statusCode === 404) {
    // Sale lot does not exist
    toast.error(
      `Sale lot does not exist anymore, so ${scans.length} scans could not be added.`,
    );

    // Put the scans back into unallocated state
    yield put(addUnassignedScans(scans));

    yield put(deleteSaleLotNoLongerExists(saleLotId));
  } else {
    toast.error(
      `Server error (${statusCode}) ${scans.length} scans could not be added.`,
    );
  }
}

const failedUpdateScanMessage = () =>
  toast.error("Error updating scans, we will try again soon.");

const failedMoveScansMessage = action => {
  const {
    meta: { eids },
  } = action;
  toast.error(
    `Error updating ${eids.length} scan${eids.length !== 1 ? "s" : ""}`,
  );
};

const scansDeleteSuccess = () => toast.success("Scans cleared.");

function* scansDeleteRollback(action) {
  const { sale } = action.meta;
  const { response } = action.payload;
  const errorText = response?.error || "Scans could not be cleared.";
  toast.error(errorText);
  yield call(fetchScans, { sale });
}

const updateNLISScansOffline = () =>
  toast.success("Updating details from NLIS.");

function* confirmStartDraft(action) {
  const { deviceId } = action;
  const state = yield select();
  const promptRequired = getUnassignedScanSummary(state).hasScans;
  if (promptRequired) {
    yield call(
      confirmDialogWithSubAction,
      {
        title: "Start a new draft",
        message:
          "Are you sure you want to clear all of the scans from this draft and start a new one?",
      },
      [startDraft(deviceId), resendScan(deviceId)],
    );
  } else {
    yield put(startDraft(deviceId));
    yield put(resendScan(deviceId));
  }
}

function* clearAllUnallocatedScans() {
  const state = yield select();
  const orphanedScanIds = Object.keys(getUnassignedScans(state));
  if (orphanedScanIds.length) {
    yield put(deleteUnassignedScans(orphanedScanIds));
  }
}

function* commitScanBufferWorker() {
  while (true) {
    // if "newScans" is empty, the delay won the race, otherwise it will be
    // an ADD_SCAN_FROM_SCANNER message
    const { timeout } = yield race({
      newScans: take(ADD_SCAN_FROM_SCANNER),
      timeout: delay(750, true),
    });

    // Check if the delay won the race, i.e. if it's been more than 750ms
    // since the last ADD_SCAN_FROM_SCANNER message
    if (timeout) {
      // flush the buffer
      yield put(addScansFromBuffer());

      // go back and wait for an ADD_SCAN_FROM_SCANNER message
      break;
    }
  }
}

export function* scheduleCommitScanBuffer() {
  // When a scan comes in, wait at least 750ms before flushing it the UI and at most, 1250ms before flushing it
  while (true) {
    // wait here until an ADD_SCAN_FROM_SCANNER message comes in
    yield take(ADD_SCAN_FROM_SCANNER);

    const { forceBufferFlush } = yield race({
      bufferFlush: call(commitScanBufferWorker),
      implicitFlush: take(ADD_SCANS_FROM_BUFFER),
      forceBufferFlush: delay(1250, true),
    });
    if (forceBufferFlush) {
      // It's been more than 1250ms since the last buffer flush, do it forcibly
      yield put(addScansFromBuffer());
    }
  }
}

function uploadNLISIDsFailure() {
  toast.error(
    `An NLIS ID wasn't registered or has been typed incorrectly. Please try again.`,
  );
}
function uploadNLISIDsSuccess(action) {
  const { payload } = action;

  toast.success(
    `${payload.scans.length} NLIS ID${
      payload.scans.length === 1 ? "" : "s"
    } added successfully.`,
  );
}

function onAddedDraftInformation() {
  toast.success("Added new scanner information");
}

export function* populateDeviceIdInScans(scans) {
  let state = yield select();
  const deviceLookup = getDeviceLookup(state);

  const scansToUpload = [];

  for (const scan of scans) {
    const uploadableScan = {
      ...scan,
    };
    // If we don't already have a drafting id, go and find/calculate one.

    if (!scan.drafting_id && (scan.device_name || scan.device_id)) {
      const { draftingInformationHash, draftName, deviceId, deviceName } =
        getDraftInformationFieldsFromScan(scan, deviceLookup);
      const existingDraftingInformationId = getDraftingInformationIdByHash(
        draftingInformationHash,
      )(state);
      if (existingDraftingInformationId) {
        uploadableScan.drafting_id = existingDraftingInformationId;
      } else {
        uploadableScan.drafting_id = uuidv4();

        const draftingInformationPayload = {
          id: uploadableScan.drafting_id,
          draftName,
          deviceId,
          deviceName,
          saleyard: getSaleyardName(),
        };

        yield put(DraftingInformationAction.create(draftingInformationPayload));
        // We have made state changes - we need them reflected so we don't keep re-making these
        state = yield select();
      }
    }
    scansToUpload.push(uploadableScan);
  }

  return scansToUpload;
}

function* onUploadScansAndDraftingInformation(action) {
  const { scans, saleLotId } = action;

  const uploadableScans = yield call(populateDeviceIdInScans, scans);

  yield put(uploadScansAction(uploadableScans, saleLotId));
}

/**
 * Checks for a sale lot attached to an "unknown" vendor, and creates each step if it doesn't exist - that is an unknonwn business, consignment, then sale lot, and selling/delivery pens if supplied.
 * Note that the pen information will be ignored if the unknown lot already exists.
 *
 * @param {{ type: string, eids: Array<string>, deploymentSaleId: number, sellingPenPayload: Object, deliveryPenPayload: Object  }} action
 */
function* onAddEidsToUnknownSaleLot(action) {
  const { eids, saleLotPayload } = action;

  const state = yield select();

  const unknownSaleLotId = uuidv4();

  yield call(
    onCreateSaleLotAction,
    addSaleLotWithPens(unknownSaleLotId, {
      ...saleLotPayload,
    }),
  );

  const scans = getScans(state);
  const deviceId = getConnectedDeviceId(state);

  const eidsToBulkMove = eids.filter(eid => scans[eid]);
  const eidsToUpload = eids
    .filter(eid => !scans[eid])
    .map(eid => ({
      EID: eid,
      created: new Date().toISOString(),
      device_id: deviceId,
    }));

  if (eidsToBulkMove.length > 0) {
    yield put(bulkMoveScans(eidsToBulkMove, unknownSaleLotId));
  }
  if (eidsToUpload) {
    yield put(uploadScansAction(eidsToUpload, unknownSaleLotId));
  }
}

export function* onResetSingleWeigh() {
  const state = yield select();

  const deviceId = getConnectedDeviceId(state);
  if (!deviceId) {
    return;
  }

  yield put(resendScan(deviceId));
}

function onDeleteScanLot(action) {
  // get the id of the pen scan lot/receival lot that is about to be deleted
  const { id } = action;

  // parse get and parse the hash route, so we can check if the user is on the scanning screen
  const hashRoute = queryString.parse(
    window.location.hash,
    {
      parseBooleans: true,
      parseNumbers: true,
    },
    {
      arrayFormat: "comma",
    },
  );
  const scanningScreenArgs = hashRoute[ModalTypes.Scan];

  if (!scanningScreenArgs) {
    // The user is not on the scanning screen, so we don't need to do anything.
    return;
  }
  const scanningScreenProps = JSON.parse(scanningScreenArgs);

  if (scanningScreenProps.scanLotId === id) {
    // The user is on the scanning screen, and the scan lot that is currently selected is about to be deleted.
    // We need to navigate to the same route without the deleted scan lot.
    // Leaving the user with the same scan lot selected will cause their next save action to attempt to save to a deleted scan lot.
    openPenScanning(
      scanningScreenProps.penArchetypeId,
      scanningScreenProps.penId,
      null,
      scanningScreenProps.resume,
      scanningScreenProps.mode,
      scanningScreenProps.penType,
      hashRoute.returnTo,
    );
  }
}

export default function* scansSagas() {
  yield takeEvery(GET_SCANS, fetchScans);
  yield takeLeading(GET_SCANS_CHANGES, fetchScansChanges);

  yield takeEvery(UPLOAD_DRAFT, uploadDraft);

  yield takeEvery(UPLOAD_SCANS_COMMIT, uploadScansSuccess);
  yield takeEvery(UPLOAD_SCANS_ROLLBACK, rollbackAddScansToSalelot);

  yield takeEvery(DELETE_SALE_LOT_SCANS_ACTION, deleteSaleLotScansAction);
  yield takeEvery(SCAN.DELETE.ACTION, deleteScanAction);
  yield takeEvery(UPLOAD_SCANS_ACTION, onUploadScansAction);

  yield takeEvery(BULK_MOVE_SCANS, onBulkMoveScans);
  yield takeEvery(BULK_MOVE_SCANS_COMMIT, updateScansSuccess);
  yield takeEvery(BULK_MOVE_SCANS_ROLLBACK, failedMoveScansMessage);

  yield takeEvery(UPDATE_SCAN_NLIS_OFFLINE, updateNLISScansOffline);
  yield takeEvery(UPDATE_SCAN_NLIS_COMMIT, updateNLISScansSuccess);
  yield takeEvery(UPDATE_SCAN_NLIS_ROLLBACK, failedUpdateScanMessage);
  yield takeEvery(CONFIRM_START_DRAFT, confirmStartDraft);
  yield takeEvery(START_DRAFT, clearAllUnallocatedScans);
  yield takeEvery(DELETE_ALL_SCANS_FROM_UNASSIGNED, clearAllUnallocatedScans);

  yield takeEvery(DELETE_SALE_LOT_SCANS_COMMIT, scansDeleteSuccess);
  yield takeEvery(DELETE_SALE_LOT_SCANS_ROLLBACK, scansDeleteRollback);

  yield takeEvery(UPLOAD_NLIS_ID_ROLLBACK, uploadNLISIDsFailure);
  yield takeEvery(UPLOAD_NLIS_ID_COMMIT, uploadNLISIDsSuccess);
  yield takeEvery(DRAFTING_INFORMATION.CREATE.SUCCESS, onAddedDraftInformation);

  yield takeEvery(
    UPLOAD_SCANS_AND_DRAFTING_INFORMATION,
    onUploadScansAndDraftingInformation,
  );

  yield takeEvery(
    GENERIC_UPLOAD_SCANS_AND_DRAFTING_INFORMATION,
    populateDeviceIdInScans,
  );

  yield takeEvery(SINGLE_WEIGH.RESET.ACTION, onResetSingleWeigh);

  yield takeEvery(
    SCAN.BULK_UPDATE_NLIS_STATUS.SUCCESS,
    fetchCurrentSaleScansChanges,
  );

  yield takeEvery(
    SCAN.ADD_TO_UNKNOWN_SALELOT.ACTION,
    onAddEidsToUnknownSaleLot,
  );

  yield takeEvery(
    [PEN_SCAN_LOT.DELETE.REQUEST, RECEIVAL_LOT.DELETE.REQUEST],
    onDeleteScanLot,
  );

  yield scheduleCommitScanBuffer();
}
