import React from "react";

import WaitForSync from "components/LoadingSpinner/WaitForSync";

import { ApiModel } from "constants/loading";
import { ModalTypes } from "constants/navigation";

import { useModalAdapter } from "hooks";

import Modal from "./modal";

function Adapter(props) {
  const [hashParams, returnTo, onClose] = useModalAdapter(
    ModalTypes.KillSheet,
    props,
  );

  const { consignmentId } = hashParams;

  return (
    <WaitForSync requiredData={[ApiModel.SALE_LOTS, ApiModel.SALES]}>
      <Modal
        consignmentId={consignmentId}
        onClose={onClose}
        returnTo={returnTo}
      />
    </WaitForSync>
  );
}

export default Adapter;
