import React from "react";

import {
  faCopy,
  faEye,
  faPencil,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

import { RuleBookAction, closeConfirmModal, openConfirmModal } from "actions";

import { ColumnType } from "components/AgGrid/constants";
import { MultiButton, SlimButton } from "components/Button";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import { RuleBookPermissions } from "constants/permissions";
import { Reports } from "constants/reports";

import { openModalLink } from "lib/navigation";
import { hasPermission } from "lib/permissions";

import { getIsLivestockAgent, getIsSaleyardAdmin } from "selectors";

import { useGetParentObjectFromActiveRole } from "hooks/useGetParentObjectFromActiveRole";

export const RuleBookColumnId = {
  NAME: "78510e55-b81e-44f7-8e4a-9452b407abd0",
  CREATED: "a3ec9cc4-406d-4906-a42e-efcd119ed692",
  EFFECTIVE_START_DATE: "9b8eec84-99df-4ef2-a91a-aef0cfc7fe48",
  EFFECTIVE_END_DATE: "5f4b16bf-586f-46fa-9178-6d237d17d36b",
  DESCRIPTION: "77985e73-c270-4796-b0a3-66a026f327b2",
  IS_ARCHIVED: "3a533a04-a770-4b9b-8472-411a1843e13e",
  CATEGORIES: "429ade13-2cd9-42ce-8499-7069a1f70637",
  TAGS: "c83be55e-8527-495d-a29c-1089baf7d054",
  REPORTS: "dd20f0c7-e922-4f13-b351-5f61169e0adf",
  SALE_SUB_TYPES: "4adc5451-0215-4b96-a1b8-677b0fdc8474",
  ACTIONS: "3398b3a5-1ed3-49c3-9396-011ec2843f8f",
};

function RuleBookActionsColumnRenderer(props) {
  const { data = {} } = props;

  const { ruleBook = {} } = data;

  const { id: ruleBookId, name: ruleBookName } = ruleBook;

  const isLivestockAgent = useSelector(getIsLivestockAgent);
  const isSaleyardAdmin = useSelector(getIsSaleyardAdmin);

  const parentObject = useGetParentObjectFromActiveRole() || {};

  const dispatch = useDispatch();

  if (!data) {
    return null;
  }

  const readOnly = !hasPermission(ruleBook, RuleBookPermissions.update);

  const hasDeletePermission = hasPermission(
    ruleBook,
    RuleBookPermissions.delete,
  );

  const deploymentId = (isLivestockAgent && parentObject.id) || null;
  const saleyardName = (isSaleyardAdmin && parentObject.name) || null;
  const createOptions = { deploymentId, saleyardName };

  const onEditRuleBook = () => {
    openModalLink(ModalTypes.RuleBook, {
      id: ruleBookId,
    });
  };

  const onCopyRuleBook = () => {
    const id = uuidv4();

    dispatch(RuleBookAction.copy(ruleBookId, id, createOptions));
  };

  const onRemoveRuleBook = () => {
    dispatch(
      openConfirmModal({
        title: "Are you sure?",
        message: `Are you sure you want to delete ${ruleBookName}?`,
        actions: [
          {
            label: "No",
            secondary: true,
            onClick: () => {
              dispatch(closeConfirmModal());
            },
          },
          {
            label: "Yes",
            onClick: () => {
              dispatch(RuleBookAction.delete(ruleBookId));
              dispatch(closeConfirmModal());
            },
          },
        ],
      }),
    );
  };

  const buttons = [
    {
      dataTour: `edit:${ruleBook.name}`,
      default: true,
      icon: readOnly ? faEye : faPencil,
      isDisabled: readOnly,
      onClick: onEditRuleBook,
      title: readOnly ? ` View` : ` Edit`,
    },
    {
      dataTour: "copyRuleBook",
      default: false,
      icon: faCopy,
      isDisabled: readOnly,
      onClick: onCopyRuleBook,
      title: "Copy",
    },
    {
      dataTour: "deleteRuleBook",
      default: false,
      icon: faTrash,
      isDisabled: !hasDeletePermission,
      onClick: onRemoveRuleBook,
      title: "Delete",
    },
  ];

  return <MultiButton buttons={buttons} ButtonComponent={SlimButton} />;
}

const ReportsGetter = params => {
  const reports = get(params.data, params.colDef.field) || [];

  const reportTitles = Object.values(Reports)
    .filter(report => reports.includes(report.slug))
    .map(report => report.title);

  return reportTitles.join(", ");
};

const SaleSubTypesGetter = params => {
  const saleSubTypes = get(params.data, params.colDef.field) || [];
  return saleSubTypes.map(saleSubType => saleSubType?.name).join(", ");
};

const CategoriesGetter = params => {
  const categories = get(params.data, params.colDef.field);
  return categories.join(", ");
};

const TagsGetter = params => {
  const billingTags = get(params.data, params.colDef.field) || [];
  return billingTags.map(billingTag => billingTag?.name).join(", ");
};

export const ruleBookColumns = [
  {
    colId: RuleBookColumnId.NAME,
    field: "ruleBook.name",
    headerName: "Name",
    width: 300,
    pinned: "left",
    type: ColumnType.STRING,
  },
  {
    colId: RuleBookColumnId.DESCRIPTION,
    field: "ruleBook.description",
    headerName: "Description",
    width: 300,
    type: ColumnType.STRING,
  },
  {
    colId: RuleBookColumnId.EFFECTIVE_START_DATE,
    field: "ruleBook.effective_start_date",
    headerName: "Effective Start Date",
    width: 200,
    type: ColumnType.DATE,
  },
  {
    colId: RuleBookColumnId.EFFECTIVE_END_DATE,
    field: "ruleBook.effective_end_date",
    headerName: "Effective End Date",
    width: 200,
    type: ColumnType.DATE,
  },
  {
    colId: RuleBookColumnId.IS_ARCHIVED,
    field: "ruleBook.is_archived",
    headerName: "Is Archived",
    width: 100,
    type: ColumnType.BOOLEAN,
  },
  {
    colId: RuleBookColumnId.REPORTS,
    field: "ruleBook.reports",
    headerName: "Reports",
    width: 300,
    type: ColumnType.STRING,
    valueGetter: ReportsGetter,
  },
  {
    colId: RuleBookColumnId.SALE_SUB_TYPES,
    field: "saleSubTypes",
    headerName: "Sale Sub Types",
    width: 300,
    type: ColumnType.STRING,
    valueGetter: SaleSubTypesGetter,
  },
  {
    colId: RuleBookColumnId.CATEGORIES,
    field: "categories",
    headerName: "Categories",
    width: 300,
    type: ColumnType.STRING,
    valueGetter: CategoriesGetter,
  },
  {
    colId: RuleBookColumnId.TAGS,
    field: "billingTags",
    headerName: "Tags",
    width: 300,
    type: ColumnType.STRING,
    valueGetter: TagsGetter,
  },
  {
    colId: RuleBookColumnId.CREATED,
    field: "ruleBook.created",
    headerName: "Created",
    width: 100,
    type: ColumnType.DATE_TIME,
  },

  {
    colId: RuleBookColumnId.ACTIONS,
    headerName: ACTION_COLUMN_NAME,
    cellRenderer: RuleBookActionsColumnRenderer,
    suppressCellFlash: true,
    width: 130,
    pinned: "right",
  },
];
