import React from "react";

import { faCopy, faFileEdit } from "@fortawesome/pro-solid-svg-icons";
import { useSelector } from "react-redux";

import { MultiButton, SlimButton } from "components/Button";

import { ACTION_COLUMN_ID, ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import { ReportJobStatus, ReportJobStatusColorMap } from "constants/reportJob";

import { getters } from "lib/agGrid";
import { datetimeFormatter } from "lib/agGrid/columns/datetime";
import { emailCountRenderer } from "lib/agGrid/renderers";
import { BusinessesRenderer } from "lib/agGrid/renderers/businessesRenderer";
import { StatusRenderer } from "lib/agGrid/renderers/statusRenderer";
import { getReportJobUrl, openModalLink } from "lib/navigation";

import { getActiveRole } from "selectors";

export const ReportJobColId = {
  BUSINESS_IDS: "095cbaf5-681c-4691-a492-dc8bf2470717",
  CREATED: "84eda079-c375-4e20-8c56-a4d8635102fe",
  DEPLOYMENT_ID: "c8da6402-470f-4e08-8545-b372523c0cbb",
  FILE: "fbd87b9e-ab51-4bcb-90cc-bcb9f8d4aec5",
  EMAIL_COUNT: "82059ef0-a885-499b-90d2-812f7c394249",
  ID: "64c59ae3-8c09-47d4-957a-034fce3d7128",
  LIVESTOCK_SALE_ID: "891d94ce-9610-4720-a097-4036d67d3d88",
  RENDER_STATUS: "b8c8c1e6-8d1f-41df-ac57-c9df8852759c",
  REPORT: "c6d6447f-c625-4e9b-9015-2ff8b98b6506",
  SALEYARD_ID: "395c291b-4792-44c9-a486-77d0a3c504eb",
  STATUS: "b37a98a3-b058-4e5b-b605-8956edb89143",
  TITLE: "7029b08c-caf1-450a-ae8e-5547f8867bbf",
};

const ReportJobActionsValueGetter = ({ data }) => {
  // check for changes in any reportJob props that are used in the cellRenderer
  const { reportJob } = data;
  return [reportJob.id, reportJob.status].join("-");
};

const ReportJobActionsCellRenderer = ({ data }) => {
  const { reportJob } = data;

  const userRole = useSelector(getActiveRole);

  const onClickView = () => {
    const url = getReportJobUrl(reportJob.id, userRole.slug);
    window.open(url);
  };

  const onClickSend = () => {
    openModalLink(ModalTypes.EmailReportJobModal, { id: reportJob.id });
  };

  const buttons = [
    {
      dataTour: "view",
      default: true,
      icon: faFileEdit,
      isDisabled: false,
      onClick: onClickView,
      title: "View",
    },
    {
      dataTour: "send",
      default: false,
      icon: faCopy,
      // isDisabled could possibly be relaxed to only disable if reportJob is ERROR
      // however there some caveats
      //  - until success, there is a chance of ERROR,
      //  - may contain invalid/unexpected information if not viewed first
      // both of which would be a poor email recipient experience - see RD-777
      isDisabled: data.reportJob.status !== ReportJobStatus.SUCCESS,
      onClick: onClickSend,
      title: "Send",
    },
  ].filter(Boolean);

  return <MultiButton buttons={buttons} ButtonComponent={SlimButton} />;
};

export const ReportJobColumnDef = {
  [ReportJobColId.CREATED]: {
    colId: ReportJobColId.CREATED,
    field: "reportJob.created",
    headerName: "Created",
    valueFormatter: datetimeFormatter,
  },
  [ReportJobColId.REPORT]: {
    colId: ReportJobColId.REPORT,
    field: "reportJob.report",
    headerName: "Report",
  },
  [ReportJobColId.BUSINESS_IDS]: {
    colId: ReportJobColId.BUSINESS_IDS,
    field: "reportJob.businessIds",
    headerName: "Business(es)",
    valueGetter: getters.businessesNamesGetter,
    cellRenderer: BusinessesRenderer,
  },
  [ReportJobColId.EMAIL_COUNT]: {
    colId: ReportJobColId.EMAIL_COUNT,
    field: "reportJob.emailIds",
    headerName: "Sent Emails",
    valueGetter: getters.arrayLengthGetter,
    valueFormatter: emailCountRenderer,
    cellRenderer: "agGroupCellRenderer",
    width: 120,
  },
  [ReportJobColId.LIVESTOCK_SALE_ID]: {
    colId: ReportJobColId.LIVESTOCK_SALE_ID,
    field: "reportJob.livestockSaleId",
    headerName: "Sale",
    valueGetter: getters.livestockSaleCodeGetter,
  },
  [ReportJobColId.STATUS]: {
    colId: ReportJobColId.STATUS,
    field: "reportJob.status",
    headerName: "Status",
    cellRenderer: StatusRenderer,
    statusRendererProps: {
      colorMap: ReportJobStatusColorMap,
    },
    hide: true,
  },
  [ReportJobColId.TITLE]: {
    colId: ReportJobColId.TITLE,
    field: "reportJob.title",
    headerName: "Title",
  },
  [ACTION_COLUMN_ID]: {
    colId: ACTION_COLUMN_ID,
    headerName: ACTION_COLUMN_NAME,
    valueGetter: ReportJobActionsValueGetter,
    cellRenderer: ReportJobActionsCellRenderer,
    pinned: "right",
    suppressCellFlash: true,
    width: 130,
  },
};
