import React from "react";

import { faRedo } from "@fortawesome/free-solid-svg-icons";
import { Grid } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { partition } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import {
  bulkCreateAutoTakePossessions,
  requestAllTakeReversals,
  requestAllTakeStatuses,
  requestScansChanges,
} from "actions";

import { reverseCorrectableTakes } from "actions/offline";

import { AgGridPreview } from "components/AgGrid/AgGridPreview";
import TableWrapper, {
  CollapseTableWrapper,
} from "components/AgGrid/TableWrapper";
import { SlimButton } from "components/Button";
import { Action, ActionIcon } from "components/Button/Actions";
import { Button } from "components/Form";
import { Tooltip } from "components/Form/FormikControls/Tooltip";
import { Column, Row } from "components/Layout";
import WaitForSync from "components/LoadingSpinner/WaitForSync";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "components/MaterialDialog";

import { AgGridTables } from "constants/aggrid";
import { Column as AgGridColumn } from "constants/columns";
import { ApiModel } from "constants/loading";
import {
  NLISFileTypes,
  SCAN_NLIS_STATUS,
  TRANSFER_STATUS,
} from "constants/nlis";

import { RollBackAlert } from "containers/NLISFiles/RollbackAlert";

import { withNestedField } from "lib/agGrid";
import { BaseColumnDefinitions } from "lib/agGrid/columnDefinitions";
import { pluralize } from "lib/pluralize";

import {
  currentSaleSelector,
  getConsignments,
  getTakeableScans,
  getTakeCorrectableScans,
  selectTakeableConsignmentIds,
  selectTransferInTransactions,
} from "selectors";

import { getIsFetchingNLISInformation } from "selectors/loading";

import { useMountEffect, useToggle } from "hooks";

import { NLISFileGrid } from "./NLISFileGrid";

const columnState = [
  {
    colId: "transferType",
  },
  {
    colId: "transactionId",
  },
  {
    colId: "status",
  },
  {
    colId: "eidStatus",
  },
  {
    colId: "vendorDisplay",
  },
  { colId: "buyerDisplay", hide: true },
  {
    colId: "created",
  },
  {
    colId: "submitted",
  },
  {
    colId: "processed",
  },
  {
    colId: "relatedTransaction",
  },
  {
    colId: "actions",
  },
];

export const confirmationColumnDefs = [
  {
    headerName: "EID",
    field: "scan.EID",
    minWidth: 90,
  },
  withNestedField(AgGridColumn.PEN, "saleLot"),
  withNestedField(AgGridColumn.VENDOR_NAME, "saleLot"),
  BaseColumnDefinitions[AgGridColumn.NVD],
  BaseColumnDefinitions[AgGridColumn.VENDOR_PIC],
  {
    field: "nlisSaleyardCredentials.nlis_saleyard_id",
    headerName: "Saleyard PIC",
  },
];

const ConfirmTransfer = ({ toggleShowConfirm }) => {
  const dispatch = useDispatch();

  const takeableScans = useSelector(getTakeableScans);

  const handleTransfer = () => {
    dispatch(
      bulkCreateAutoTakePossessions(
        takeableScans.map(scanRow => scanRow.scan.EID),
      ),
    );
    toggleShowConfirm();
  };

  return (
    <Dialog open onClose={toggleShowConfirm} maxWidth="lg" fullWidth>
      <DialogTitle onClose={toggleShowConfirm}>
        Are you sure you wish to Take the following EIDs?
      </DialogTitle>
      <DialogContent>
        <CollapseTableWrapper>
          <AgGridPreview
            rowData={takeableScans}
            columnDefs={confirmationColumnDefs}
            tableName={AgGridTables.NLIS_TAKE_PREVIEW}
          />
        </CollapseTableWrapper>
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleShowConfirm}>Cancel</Button>
        <Button data-tour="submit" onClick={handleTransfer}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const NLISTakeFileInfo = React.memo(() => {
  const dispatch = useDispatch();

  const takeableConsignmentIds = useSelector(selectTakeableConsignmentIds);
  const sale = useSelector(currentSaleSelector);
  const isFetching = useSelector(getIsFetchingNLISInformation);
  const consignments = useSelector(getConsignments);
  const takeableScanCount = useSelector(getTakeableScans).length;

  const takeCorrectableScans = useSelector(getTakeCorrectableScans);

  const rowData = useSelector(selectTransferInTransactions);
  const [showConfirm, toggleShowConfirm] = useToggle(false);
  const handleRefresh = React.useCallback(() => {
    if (!isFetching) {
      dispatch(requestAllTakeStatuses(sale));
      dispatch(requestAllTakeReversals(sale));
      dispatch(requestScansChanges(sale));
    }
  }, [isFetching, sale, dispatch]);

  const [requiresSellRollbackScans, correctableScans] = partition(
    takeCorrectableScans,
    scan => scan.scan.nlis_sell_status === SCAN_NLIS_STATUS.NEEDS_CORRECTING,
  );

  const totalConsignments = Object.keys(consignments).length;

  const numActionable = takeableConsignmentIds.length;

  const transferText = `You currently have ${takeableScanCount} Takeable ${pluralize(
    "EID",
    takeableScanCount,
  )} in ${numActionable} (of ${totalConsignments}) ${pluralize(
    "Consignment",
    numActionable,
  )}`;

  useMountEffect(() => {
    // If we have no rows on mount, refresh from the backend.
    // (If we run this when rowData changes, it will continually refresh if there is actually no data.)
    if (rowData.length === 0) {
      handleRefresh();
    }
  });

  // If there are any unsubmitted transactions, don't allow the user to action any more.
  const hasUnsubmittedTransfers = rowData.some(
    nlisTransaction => nlisTransaction.status === TRANSFER_STATUS.UNSUBMITTED,
  );
  const hasActionable = numActionable > 0;
  const showAction = hasActionable && !hasUnsubmittedTransfers;

  const reverseAll = () => {
    dispatch(reverseCorrectableTakes(sale));
  };

  const numRequiringSellRollback = requiresSellRollbackScans?.length;
  const numCorrectable = correctableScans?.length || 0;
  const hasCorrectable = numCorrectable > 0;
  const showRollback = hasCorrectable && !hasUnsubmittedTransfers;

  const tooltipText = hasUnsubmittedTransfers
    ? "Transfer in progress, please wait"
    : isFetching
      ? "Refreshing data, please wait"
      : "Transfer Takeable EIDs";
  const AlertAction = (
    <Tooltip title={tooltipText}>
      <SlimButton
        data-tour="transferNow"
        onClick={toggleShowConfirm}
        disabled={hasUnsubmittedTransfers || isFetching}
      >
        Transfer Now
      </SlimButton>
    </Tooltip>
  );

  return (
    <WaitForSync
      requiredData={[
        ApiModel.NLIS_TAKE_FILES,
        ApiModel.NLIS_TAKE_REVERSALS,
        ApiModel.SCANS,
        ApiModel.PROPERTIES,
      ]}
    >
      <Row flexGrow justifyBetween>
        <Column fullWidth padding={2}>
          {showRollback && (
            <RollBackAlert
              correctableTransfers={correctableScans}
              disableAction={hasUnsubmittedTransfers || isFetching}
              handleCorrection={reverseAll}
              nlisFileType={NLISFileTypes.NLISTakeFile}
            />
          )}

          {showAction && (
            <Grid item xs={12}>
              <Alert severity="error" action={AlertAction}>
                {transferText}
              </Alert>
            </Grid>
          )}

          {!hasCorrectable && !hasActionable && (
            <Grid item xs={12}>
              <Alert severity="success">
                All available Consignments have been transferred.
              </Alert>
            </Grid>
          )}

          {numRequiringSellRollback > 0 && (
            <Grid item xs={12}>
              <Alert severity="warning">
                We have detected changes made affecting{" "}
                {numRequiringSellRollback}{" "}
                {pluralize("transfer", numCorrectable)} since the last NLIS
                Transaction. These transfers are unable to be rolled back until
                their related sell transfer has been rolled back.
              </Alert>
            </Grid>
          )}
        </Column>

        <Action onClick={handleRefresh} disabled={isFetching}>
          <ActionIcon icon={faRedo} />
          Refresh
        </Action>
      </Row>

      <Column fullHeight>
        <TableWrapper>
          <NLISFileGrid rowData={rowData} columnState={columnState} />
        </TableWrapper>
      </Column>

      {showConfirm && <ConfirmTransfer toggleShowConfirm={toggleShowConfirm} />}
    </WaitForSync>
  );
});
