import styled from "styled-components/macro";

export const TitledRule = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  ::before,
  ::after {
    content: "";
    flex: 1;
    border-bottom: 2px groove threedface;
  }

  :not(:empty)::before {
    margin-right: 1em;
  }

  :not(:empty)::after {
    margin-left: 1em;
  }
`;
