import React from "react";

import { faEye, faPencil } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActionButton,
  ActionButtonContainer,
} from "components/AgGrid/ActionButton";
import { ColumnType } from "components/AgGrid/constants";

import { ACTION_COLUMN_NAME } from "constants/aggrid";
import { ModalTypes } from "constants/navigation";
import { RuleValueFormatDisplayName } from "constants/ruleBooks";

import { getters } from "lib/agGrid";
import { nullableBooleanRenderer } from "lib/agGrid/renderers/tickRenderer";
import { openModalLink } from "lib/navigation";
import {
  ledgerEntryPriceFormatter,
  ledgerEntryQuantityFormatter,
} from "lib/numberFormatters";

export const LedgerEntryColId = {
  ACTIONS: "23922201-efde-45ef-9501-5cfcfe97394d",
  CATEGORY: "abbecc55-a348-42e9-9b38-438ca49ed8e0",
  DESCRIPTION: "4950367d-1d70-4e5b-94ff-04234198546c",
  GL_CODE: "358c6d7f-c724-4a8c-ac68-6d74a8fba388",
  GST: "289f0618-a762-4bd8-ac9b-f022eab9992e",
  INCLUDE_IF_ZERO: "544898ad-576b-419f-bce3-b76987b6dd41",
  IS_MODIFIED: "dc7f53d2-9676-495e-9107-9df2af8829d0",
  ITEM_TYPE: "bc3b669b-027f-41d4-a58f-3f10adc39620",
  INVOICE_CATEGORY: "3b55b4d3-e776-4e6e-b1ae-78f317519e9c",
  LEDGER_ENTRY: "03e4c47d-bd5c-422c-a766-defe366f8d36",
  LINE_ITEM_GROUPED: "4d3f7468-89ec-405d-b2ef-ca506b6054c3",
  MINCENOUS_ID: "c4701c56-e46c-47a1-af9d-095ab1713c48",
  NOTES: "7cdbac37-ddf8-494e-9650-c5298c0a5e0f",
  QUANTITY_RAW_FORMAT: "5ba589c1-b7c6-44d8-86aa-7acf1a3687a7",
  QUANTITY_OUTPUT_FORMAT: "a7524716-bd81-4a3d-8bfa-22389633de80",
  RULE_NAME: "abba8607-0a66-49a8-bede-3441ddbf7404",
  TAX_TYPE: "a9f1fa65-c5f2-452f-bdfc-716a4eb58199",
  TOTAL_EX: "f03caac4-53a9-4b63-a241-b4eb85b2c3de",
  TOTAL_INC: "76abed98-0ec9-448e-b471-e424bbd11439",
  UNIT_PRICE_EX: "44a78dc5-489b-4f82-a325-200bc9202da5",
  UNITS: "6b1f97c6-d078-44e6-8147-3082f8627b92",
  UNIT_AMOUNT_RAW_FORMAT: "a7ffc0bd-326c-4548-bfd8-807b68b3a770",
  UNIT_AMOUNT_OUTPUT_FORMAT: "02d86e83-8103-4fca-91b3-bec7ef9c644a",
  TOTAL_CENTS: "12f643ef-2ffa-422c-b391-610e6da6764f",
  BILLING_TAGS: "f2b5c062-e5e1-4ae8-a441-0a4ee95b96be",
  CREDIT_AMOUNT: "a8568e35-3ee4-49af-bd39-a98a3fb35c87",
  DEBIT_AMOUNT: "50a0cebf-9a4e-4c3f-9b09-29ca77393f97",

  // Raw ledger entry values.
  INVOICE_LINE_ITEM: "977b4dbb-6e97-4ea7-b91f-8cd9f57e4336",
  TITLE: "41af7b73-5dd7-4c0a-8fa3-1292af8f459f",
  REFERENCE_OBJECT: "11549b33-a563-45b0-be1c-585136e014f1",

  FROM_BUSINESS_NAME: "417aa471-1ece-4a11-83c9-7af14b9e19f6",
  TO_BUSINESS_NAME: "ff22c828-c165-49b6-bb6a-c8fd6b65e332",
};

function LedgerEntryActionsColumnRenderer(props) {
  const { node, context } = props;

  if (node.group) {
    return null;
  }

  function onClickEditLedgerEntry() {
    openModalLink(ModalTypes.EditLedgerEntry, {
      ledgerEntryId: node.id,
    });
  }

  return (
    <ActionButtonContainer>
      <ActionButton
        data-tour="action-edit-ledger-entry"
        onClick={onClickEditLedgerEntry}
      >
        <FontAwesomeIcon icon={context.readOnly ? faEye : faPencil} />
        &nbsp;Ledger Entry
      </ActionButton>
    </ActionButtonContainer>
  );
}

const RuleValueFormatFilterValueGetter = params => {
  const format = params.getValue(params.colDef.field, params.node);
  return RuleValueFormatDisplayName[format];
};
const RuleValueFormatValueFormatter = params =>
  RuleValueFormatDisplayName[params.value];

export const LedgerEntryColumnDef = {
  [LedgerEntryColId.BILLING_TAGS]: {
    colId: LedgerEntryColId.BILLING_TAGS,
    field: "ledgerEntry.tagIds",
    enableRowGroup: true,
    headerName: "Tags",
    valueGetter: getters.billingTagGetter,
  },
  [LedgerEntryColId.CREDIT_AMOUNT]: {
    colId: LedgerEntryColId.CREDIT_AMOUNT,
    field: "ledgerEntry.creditAmount",
    headerName: "Credit Amount",
    aggFunc: "sum",
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [LedgerEntryColId.DEBIT_AMOUNT]: {
    colId: LedgerEntryColId.DEBIT_AMOUNT,
    headerName: "Debit Amount",
    field: "ledgerEntry.debitAmount",
    aggFunc: "sum",
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [LedgerEntryColId.GST]: {
    aggFunc: "sum",
    colId: LedgerEntryColId.GST,
    field: "ledgerEntry.taxAmount",
    headerName: "GST ($)",
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [LedgerEntryColId.CATEGORY]: {
    colId: LedgerEntryColId.CATEGORY,
    enableRowGroup: true,
    enablePivot: true,
    field: "ledgerEntry.category",
    headerName: "Category",
  },
  [LedgerEntryColId.DESCRIPTION]: {
    colId: LedgerEntryColId.DESCRIPTION,
    enableRowGroup: true,
    enablePivot: true,
    field: "ledgerEntry.description",
    headerName: "Description",
  },
  [LedgerEntryColId.INVOICE_CATEGORY]: {
    colId: LedgerEntryColId.INVOICE_CATEGORY,
    enableRowGroup: true,
    field: "ledgerEntry.invoiceCategory",
    headerName: "Invoice Category",
    hide: true,
    openByDefault: false,
  },
  [LedgerEntryColId.UNIT_PRICE_EX]: {
    aggFunc: "anAggFunc",
    aggFuncParams: {
      decimalPlaces: 4,
    },
    colId: LedgerEntryColId.UNIT_PRICE_EX,
    field: "ledgerEntry.unitAmount",
    headerName: "Unit Price ($)",
    type: ColumnType.CURRENCY_FROM_CENTS,
    valueFormatter: ({ value }) => ledgerEntryPriceFormatter.format(value),
  },
  [LedgerEntryColId.UNITS]: {
    aggFunc: "sum",
    colId: LedgerEntryColId.UNITS,
    field: "ledgerEntry.quantity",
    headerName: "Units",
    type: ColumnType.NUMERIC,
    valueFormatter: ({ value }) => ledgerEntryQuantityFormatter.format(value),
  },
  [LedgerEntryColId.TOTAL_EX]: {
    aggFunc: "sum",
    colId: LedgerEntryColId.TOTAL_EX,
    field: "ledgerEntry.subtotal",
    headerName: "Amount ($)",
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [LedgerEntryColId.TOTAL_INC]: {
    aggFunc: "sum",
    colId: LedgerEntryColId.TOTAL_INC,
    headerName: "Total ($)",
    hide: true,
    valueGetter: ({ api, node }) =>
      node.group
        ? null
        : api.getValue(LedgerEntryColId.TOTAL_EX, node) +
          api.getValue(LedgerEntryColId.GST, node),
    type: ColumnType.CURRENCY_FROM_CENTS,
  },
  [LedgerEntryColId.INCLUDE_IF_ZERO]: {
    colId: LedgerEntryColId.INCLUDE_IF_ZERO,
    enableRowGroup: true,
    field: "ledgerEntry.includeIfZero",
    headerName: "Include If Zero",
    hide: true,
    cellRenderer: nullableBooleanRenderer,
    valueGetter: getters.isDefinedGetter,
  },
  [LedgerEntryColId.TAX_TYPE]: {
    colId: LedgerEntryColId.TAX_TYPE,
    enableRowGroup: true,
    field: "ledgerEntry.taxType",
    headerName: "Tax Type",
    hide: true,
  },
  [LedgerEntryColId.GL_CODE]: {
    colId: LedgerEntryColId.GL_CODE,
    enableRowGroup: true,
    field: "ledgerEntry.glCode",
    headerName: "GL Code",
    hide: true,
  },
  [LedgerEntryColId.IS_MODIFIED]: {
    colId: LedgerEntryColId.IS_MODIFIED,
    enableRowGroup: true,
    field: "ledgerEntry.isModified",
    headerName: "Edited",
    hide: true,
  },

  [LedgerEntryColId.RULE_NAME]: {
    colId: LedgerEntryColId.RULE_NAME,
    enablePivot: true,
    enableRowGroup: true,
    field: "ledgerEntry.ruleName",
    headerName: "Rule Name",
    hide: true,
  },
  [LedgerEntryColId.ITEM_TYPE]: {
    colId: LedgerEntryColId.ITEM_TYPE,
    enableRowGroup: true,
    field: "ledgerEntry.itemType",
    headerName: "Item Type",
    hide: true,
  },
  [LedgerEntryColId.ACTIONS]: {
    colId: LedgerEntryColId.ACTIONS,
    cellRenderer: LedgerEntryActionsColumnRenderer,
    headerName: ACTION_COLUMN_NAME,
    width: 90,
    valueGetter: ({ data }) => {
      if (!data) {
        return null;
      }
      return data.ledgerEntry.id;
    },
  },
  [LedgerEntryColId.NOTES]: {
    colId: LedgerEntryColId.NOTES,
    enableRowGroup: true,
    field: "ledgerEntry.notes",
    headerName: "Notes",
  },
  [LedgerEntryColId.QUANTITY_RAW_FORMAT]: {
    colId: LedgerEntryColId.QUANTITY_RAW_FORMAT,
    field: "ledgerEntry.quantityRawFormat",
    filterValueGetter: RuleValueFormatFilterValueGetter,
    headerName: "Quantity Source Type",
    valueFormatter: RuleValueFormatValueFormatter,
  },
  [LedgerEntryColId.QUANTITY_OUTPUT_FORMAT]: {
    colId: LedgerEntryColId.QUANTITY_OUTPUT_FORMAT,
    field: "ledgerEntry.quantityOutputFormat",
    filterValueGetter: RuleValueFormatFilterValueGetter,
    headerName: "Quantity Display Format",
    valueFormatter: RuleValueFormatValueFormatter,
  },
  [LedgerEntryColId.UNIT_AMOUNT_RAW_FORMAT]: {
    colId: LedgerEntryColId.UNIT_AMOUNT_RAW_FORMAT,
    field: "ledgerEntry.unitAmountRawFormat",
    filterValueGetter: RuleValueFormatFilterValueGetter,
    headerName: "Unit Amount Source Type",
    valueFormatter: RuleValueFormatValueFormatter,
  },
  [LedgerEntryColId.UNIT_AMOUNT_OUTPUT_FORMAT]: {
    colId: LedgerEntryColId.UNIT_AMOUNT_OUTPUT_FORMAT,
    field: "ledgerEntry.unitAmountOutputFormat",
    filterValueGetter: RuleValueFormatFilterValueGetter,
    headerName: "Unit Amount Display Format",
    valueFormatter: RuleValueFormatValueFormatter,
  },
};

export const ExtendedLedgerEntryColumnDef = {
  ...LedgerEntryColumnDef,
  [LedgerEntryColId.INVOICE_LINE_ITEM]: {
    colId: LedgerEntryColId.INVOICE_LINE_ITEM,
    enableRowGroup: true,
    field: "ledgerEntry.invoiceLineItem",
    headerName: "Invoice Line Item",
  },

  [LedgerEntryColId.TITLE]: {
    colId: LedgerEntryColId.TITLE,
    enableRowGroup: true,
    field: "ledgerEntry.title",
    headerName: "Title",
  },

  [LedgerEntryColId.REFERENCE_OBJECT]: {
    colId: LedgerEntryColId.REFERENCE_OBJECT,
    enableRowGroup: true,
    field: "ledgerEntry.referenceObject",
    headerName: "Reference Object",
  },
};
