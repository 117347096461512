import React, { useState } from "react";

import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { orderBy, sumBy } from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RowCard } from "components/Card";

import { formatPercentage } from "lib";

import {
  selectCurrentSaleStatisticData,
  selectCurrentScaleStatisticData,
  selectCurrentScannerStatisticData,
  getDraftingInformation,
} from "selectors";

const SetWidthRowCard = styled(RowCard)`
  max-width: 1330px;
`;

const Table = styled.table(
  ({ theme, status, isMobile }) => `
          border-spacing: 0;
          border-left: ${theme.space[1]}px ${theme.colors[status]} solid;
          width: 100%;
          padding: ${theme.space[1]}px ${theme.space[3]}px ${
            theme.space[0]
          }px ${theme.space[0]}px;
          text-align: right;
          td {
            padding: ${theme.space[1]}px;
          }
          thead th{
            text-transform: uppercase;
            font-size: ${theme.space[2]}px;
            border-bottom: 4px solid ${theme.colors.primary};
            width: 14.285714286%;
          }
          .heading-sale-stats th{
            width: ${!isMobile ? 100 / 11 : 100 / 7}%;
          }
          th.spacer{
            border: none;
            width: ${theme.space[2]}px;
          }
          `,
);

const TableContainer = styled.div(
  ({ isOpen }) => `
  width: 100%;
  max-height: ${isOpen ? "100%" : "170px"};
  overflow-y: ${isOpen ? "visible" : "scroll"};
  position: relative;
`,
);

const TableHeading = styled.thead(
  ({ theme }) => `
  position: sticky;
  top: 0;
  background: ${theme.colors.white};
`,
);

const TableFooter = styled.tfoot(
  ({ theme, fixed }) => `
  padding: ${theme.space[1]}px ${theme.space[3]}px ${theme.space[0]}px ${
    theme.space[0]
  }px;
  td{
    border-top: 1px solid ${theme.colors.primary};
    padding: ${theme.space[1]}px;
    font-weight: ${theme.fontWeights.bold};
  }
  td.spacer{
    border: none;
    width: ${theme.space[2]}px;
  }
  position: ${fixed ? "sticky" : "static"};
  bottom: ${theme.space[0]}px;
  background: ${theme.colors.white};
  border-top: 4px solid ${theme.colors.primary};
`,
);

const ExpandButtonContainer = styled.div(
  ({ theme }) => `
  width: ${theme.space[4]}px;
  height: ${theme.space[4]}px;
  position: absolute;
  bottom: -${theme.space[2]}px;
  left: 0px;
`,
);

const CollapseIcon = styled(FontAwesomeIcon)`
  ${({ theme }) => ` 
  font-size: ${theme.fontSizes.delta}px;
  color: ${theme.colors.gray40};
  cursor: pointer;
  `};
`;

const ExpandTableButton = ({ isOpen }) => {
  return (
    <ExpandButtonContainer>
      <CollapseIcon icon={isOpen ? faChevronUp : faChevronDown} />
    </ExpandButtonContainer>
  );
};

export const BasicSaleStatisticTable = ({ isMobile }) => {
  const saleStatisticData = useSelector(selectCurrentSaleStatisticData);
  const {
    head,
    progeny,
    animalsScanned,
    lotQuantity,
    lotsWeighed,
    lotsSold,
    pensUsed,
  } = saleStatisticData;

  const title = {
    head: isMobile ? "Hd" : "Head",
    progeny: isMobile ? "Prgny" : "Progeny",
    eids: "Scans",
    lotQuantity: (
      <>
        Lot
        <br />
        Qty
      </>
    ),
    lotsWeighed: isMobile ? (
      <>
        Lots
        <br />
        Wghd
      </>
    ) : (
      <>
        Lots
        <br />
        Weighed
      </>
    ),
    lotsSold: (
      <>
        Lots
        <br />
        Sold
      </>
    ),
    pensUsed: "Pens Used",
  };

  return (
    <SetWidthRowCard>
      <Table status="primary" isMobile={isMobile}>
        <thead>
          <tr className="heading-sale-stats">
            <th className="spacer" aria-label="spacer" />
            <th>{title.head}</th>
            <th>{title.progeny}</th>
            <th>{title.eids}</th>
            <th>{title.lotQuantity}</th>
            <th>{title.lotsWeighed}</th>
            <th>{title.lotsSold}</th>
            <th>{title.pensUsed}</th>
            <th className="spacer" aria-label="spacer" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td>{head}</td>
            <td>{progeny}</td>
            <td>{animalsScanned}</td>
            <td>{lotQuantity}</td>
            <td>{lotsWeighed}</td>
            <td>{lotsSold}</td>
            <td>{pensUsed}</td>
            <td />
          </tr>
        </tbody>
      </Table>
    </SetWidthRowCard>
  );
};

export const SaleStatisticTable = ({ isMobile }) => {
  const saleStatisticData = useSelector(selectCurrentSaleStatisticData);
  const {
    head,
    progeny,
    animalsScanned,
    lotQuantity,
    averageLotSize,
    lotsWeighed,
    lotsUnweighed,
    lotsSold,
    openAuctionLots,
    pensUsed,
    averagePenDensity,
  } = saleStatisticData;

  return (
    <SetWidthRowCard>
      <Table status="primary" isMobile={isMobile}>
        <thead>
          <tr className="heading-sale-stats">
            <th className="spacer" aria-label="spacer" />
            <th>Head</th>
            <th>Progeny</th>
            <th>Scans</th>
            <th>
              Lot
              <br />
              Qty
            </th>
            <th>
              Av Lot
              <br />
              Size
            </th>
            <th>
              Lots
              <br />
              Weighed
            </th>
            <th>
              Lots
              <br />
              Unweighed
            </th>
            <th>
              Lots
              <br />
              Sold
            </th>
            <th>OA Lots</th>
            <th>Pens Used</th>
            <th>
              Av Pen
              <br />
              Density
            </th>
            <th className="spacer" aria-label="spacer" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td />
            <td>{head}</td>
            <td>{progeny}</td>
            <td>{animalsScanned}</td>
            <td>{lotQuantity}</td>
            <td>{averageLotSize}</td>
            <td>{lotsWeighed}</td>
            <td>{lotsUnweighed}</td>
            <td>{lotsSold}</td>
            <td>{openAuctionLots}</td>
            <td>{pensUsed}</td>
            <td>{averagePenDensity}</td>
            <td />
          </tr>
        </tbody>
      </Table>
    </SetWidthRowCard>
  );
};

export const ScaleStatisticsOverViewComponent = () => {
  const scaleStatisticData = useSelector(selectCurrentScaleStatisticData);
  const draftingInformationLookup = useSelector(getDraftingInformation);
  const totalWeighingTime = sumBy(scaleStatisticData, "weighingTime");
  const totalLotQuantity = sumBy(scaleStatisticData, "lotQuantity");
  const totalLotHeadQuantity = sumBy(scaleStatisticData, "lotHeadQuantity");

  return (
    <SetWidthRowCard>
      <Table status="primary">
        <thead>
          <tr>
            <th className="spacer" aria-label="spacer" />
            <th colSpan={2}>Scale Statistics</th>
            <th aria-label="spacer" />
            <th>Weighing Time (hrs)</th>
            <th>Lot Hd Qty</th>
            <th>Lot Qty</th>
            <th>Lots per Hour</th>
            <th>Head Per Hour</th>
            <th className="spacer" aria-label="spacer" />
          </tr>
        </thead>
        <tbody>
          {Object.values(scaleStatisticData).map(data => {
            const scale = draftingInformationLookup[data.id];
            return (
              <tr>
                <td />
                <td colSpan={2}>{scale.commonName || scale.deviceName}</td>
                <td />
                <td>{data.weighingTime}</td>
                <td>{data.lotHeadQuantity}</td>
                <td>{data.lotQuantity}</td>
                <td>{Math.round(data.lotsPerHour)}</td>
                <td>{Math.round(data.headPerHour)}</td>
                <td />
              </tr>
            );
          })}
        </tbody>
        <TableFooter>
          <tr>
            <td className="spacer" />
            <td colSpan={2}>Totals</td>
            <td />
            <td>{totalWeighingTime}</td>
            <td>{totalLotHeadQuantity}</td>
            <td>{totalLotQuantity}</td>
            <td />
            <td />
            <td className="spacer" />
          </tr>
        </TableFooter>
      </Table>
    </SetWidthRowCard>
  );
};

export const ScannerStatisticsOverViewComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const scannerStatisticData = useSelector(selectCurrentScannerStatisticData);
  const draftingInformationLookup = useSelector(getDraftingInformation);
  const totalEidsScanned = sumBy(scannerStatisticData, "eidsScanned");
  const totalEidPercentage = sumBy(scannerStatisticData, "eidPercentage");
  const totalEidsPerHour = sumBy(scannerStatisticData, "eidsPerHour");
  const totalSyTagsUsed = sumBy(scannerStatisticData, "syTagsUsed");
  const totalNotRegistered = sumBy(scannerStatisticData, "notRegistered");

  return (
    <SetWidthRowCard>
      <TableContainer isOpen={isOpen}>
        <Table status="primary">
          <TableHeading>
            <tr>
              <th className="spacer" aria-label="spacer" />
              <th colSpan={2}>Scanner Statistics</th>
              <th aria-label="spacer" />
              <th>EID&apos;s Scanned</th>
              <th>EID % of Yard</th>
              <th>EIDs per hour</th>
              <th>SY Tags Used</th>
              <th>Not Registered</th>
              <th className="spacer" aria-label="spacer" />
            </tr>
          </TableHeading>
          <tbody>
            {orderBy(
              Object.values(scannerStatisticData),
              "eidsScanned",
              "desc",
            ).map(data => {
              const scanner = draftingInformationLookup[data.id];
              return (
                <tr key={data.id}>
                  <td />
                  <td colSpan={2}>
                    {scanner
                      ? scanner.commonName || scanner.deviceName
                      : "Manual Entry"}
                  </td>
                  <td />
                  <td>{data.eidsScanned}</td>
                  <td>
                    {formatPercentage(data.eidPercentage, {
                      decimalPlaces: 2,
                      includeSymbol: false,
                      roundingMethod: null,
                    })}
                  </td>
                  <td>{Math.round(data.eidsPerHour)}</td>
                  <td>{data.syTagsUsed}</td>
                  <td>{data.notRegistered}</td>
                </tr>
              );
            })}
          </tbody>
          <TableFooter fixed onClick={() => setIsOpen(!isOpen)}>
            <ExpandTableButton isOpen={isOpen} />
            <tr>
              <td className="spacer" aria-label="spacer" />
              <td colSpan={2}>Totals</td>
              <td />
              <td>{totalEidsScanned}</td>
              <td>
                {formatPercentage(totalEidPercentage, {
                  decimalPlaces: 2,
                  includeSymbol: false,
                  roundingMethod: null,
                })}
              </td>
              <td>{Math.round(totalEidsPerHour)}</td>
              <td>{totalSyTagsUsed}</td>
              <td>{totalNotRegistered}</td>
              <td className="spacer" />
            </tr>
          </TableFooter>
        </Table>
      </TableContainer>
    </SetWidthRowCard>
  );
};
