import React, { useRef, useState } from "react";

import { faEllipsis } from "@fortawesome/pro-regular-svg-icons";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup, Divider, MenuItem, Popover } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { setSetting } from "actions";

import { SecondaryStyles } from "components/Button/Styles";

import { getSettings } from "selectors/root";

import { useBoolean } from "hooks/useBoolean";

import Button from "./Button";
import { SubMenuPopover } from "./SubMenuPopover";

const DefaultButton = styled(Button)(
  ({ theme }) => `
  padding: ${theme.space[0]}px ${theme.space[2]}px;
`,
);

export const SecondaryMultiButton = styled(DefaultButton)(SecondaryStyles);

const ButtonIcon = styled(FontAwesomeIcon)(
  ({ theme, color }) => `
  color: ${color ? theme.colors[color] : theme.colors.white};
  margin: ${theme.space[0]}px ${theme.space[1]}px ${theme.space[0]}px ${
    theme.space[0]
  }px;
`,
);

const DefaultButtonIcon = styled(ButtonIcon)`
  color: ${({ theme }) => theme.colors.white};
`;

export function ContextMenuPopoverButton(props) {
  const {
    ButtonComponent = Button,
    buttonType = "button",
    buttonProps,
    anchorRef = null,
    buttons,
    stickySetting,
    includeSearchSubNavThreshold = null,
    restrictHeightSubNav = false,
    actionsOnly = false,
    actionsOnlyLabel = "Actions",
  } = props;

  const dispatch = useDispatch();

  const [activeButton, setActiveButton] = useState(null);

  const [isMenuOpen, _ignored, closeMenu, _ignored2, toggleMenu] =
    useBoolean(false);

  const onClose = () => {
    closeMenu();
    setActiveButton(null);
  };

  // Used to anchor the popover on (something) - if we've been supplied one (eg from the multibutton) use
  // that.  If we haven't, use the ... buttton.
  const localAnchorRef = useRef(null);
  const popoverAnchorRef = anchorRef || localAnchorRef;

  const subNavAnchorRef = useRef(null);

  const activeSubNavItems = React.useMemo(() => {
    return buttons[activeButton]?.subNavItems;
  }, [buttons, activeButton]);

  return (
    <>
      <ButtonComponent
        flex={actionsOnly ? 1 : 0}
        data-tour="optionsButton"
        inline
        onClick={toggleMenu}
        type={buttonType}
        ref={localAnchorRef}
        padding={2}
        {...buttonProps}
        marginThreshold={1}
      >
        {actionsOnly ? <>{actionsOnlyLabel} &nbsp;&nbsp;</> : ""}
        <FontAwesomeIcon icon={faEllipsis} />
      </ButtonComponent>
      <Popover
        open={isMenuOpen}
        anchorEl={popoverAnchorRef.current}
        data-tour="popover"
        onClose={onClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        marginThreshold={1}
      >
        {buttons
          .filter(button => !button.hide)
          .map((button, index) => {
            const {
              onClick,
              key,
              title,
              titleBold,
              icon,
              isDisabled,
              dataTour = title,
              dividerAfter = false,
              subNavItems = [],
              iconColor = "black",
              tooltip,
            } = button;

            const open = activeButton === index;
            return (
              <React.Fragment key={key || title}>
                <MenuItem
                  onClick={e => {
                    if (isDisabled) {
                      e.stopPropagation();
                    } else if (
                      subNavItems?.length > 0 &&
                      activeButton === null
                    ) {
                      subNavAnchorRef.current = e.currentTarget;
                      setActiveButton(index);
                    } else if (open && activeButton !== null) {
                      setActiveButton(null);
                    } else if (isMenuOpen) {
                      // Only handle the click if the isMenuOpen.  Without this, users can rapidly click the menu
                      // and the onClick can run many times before the menu is closed.

                      stickySetting &&
                        dispatch(setSetting(stickySetting, title));
                      onClose();
                      onClick && onClick(e);
                    }
                  }}
                  disabled={isDisabled}
                  data-tour={dataTour}
                  title={tooltip || typeof title === "string" ? title : null}
                >
                  {icon && <ButtonIcon icon={icon} color={iconColor} />}
                  <span className={titleBold ? "font-bold" : ""}>{title}</span>
                  &nbsp;
                  {subNavItems?.length > 0 && (
                    <ButtonIcon icon={faCaretRight} color={iconColor} />
                  )}
                </MenuItem>
                {dividerAfter ? <Divider /> : null}
              </React.Fragment>
            );
          })
          .filter(Boolean)}
      </Popover>
      <SubMenuPopover
        subNavItems={activeSubNavItems}
        anchorEl={subNavAnchorRef.current}
        onClose={onClose}
        open={Boolean(activeButton !== null && activeSubNavItems)}
        includeSearchSubNavThreshold={includeSearchSubNavThreshold}
        restrictHeightSubNav={restrictHeightSubNav}
      />
    </>
  );
}

export const MultiButtonComponent = ({
  actionsOnly = false,
  actionsOnlyLabel = "Actions",
  buttons,
  ButtonComponent = DefaultButton,
  inline = true,
  fullWidth = false,
  fullHeight = false,
  preferEnabledAsDefault = false,
  includeSearchSubNavThreshold = null,
  restrictHeightSubNav = false,
  stickySetting = null,
  buttonType = "button",
  moreButtonProps = {},
  orientation = "horizontal",
}) => {
  const anchorRef = useRef(null);

  const [
    isDefaultButtonMenuOpen,
    openDefaultButtonMenu,
    closeDefaultButtonMenu,
  ] = useBoolean(false);

  const defaultButtonTitleFromSettings = useSelector(
    // Avoiding circular dependency crashing.
    // getSetting(stickySetting)
    state => getSettings(state)[stickySetting],
  );
  const enabledButtons = buttons.filter(b => !b.isDisabled);
  const defaultButton = React.useMemo(() => {
    // If there is a sticky setting, prefer this as the default.
    if (defaultButtonTitleFromSettings) {
      const defaultButtonFromSettings = buttons.find(
        b => b.title === defaultButtonTitleFromSettings,
      );
      if (defaultButtonFromSettings) {
        return defaultButtonFromSettings;
      }
    }
    return preferEnabledAsDefault
      ? enabledButtons.find(b => b.default) || enabledButtons[0] || buttons[0]
      : buttons.find(b => b.default) || buttons[0];
  }, [
    buttons,
    defaultButtonTitleFromSettings,
    enabledButtons,
    preferEnabledAsDefault,
  ]);

  const groupStyle = {};
  if (fullHeight) {
    groupStyle.height = "100%";
  }

  const showMore = buttons.some(b => b !== defaultButton);

  if (!defaultButton) {
    return null;
  }

  const defaultHasSubMenu = defaultButton.subNavItems?.length > 0;

  const defaultOnClick = e => {
    if (defaultButton.onClick) {
      defaultButton.onClick(e);
    }

    if (defaultHasSubMenu) {
      openDefaultButtonMenu();
    }
  };

  let moreButtons = buttons;
  if (!actionsOnly) {
    moreButtons = buttons.filter(button => button !== defaultButton);
  }

  if (typeof moreButtonProps.borderRadius === "undefined") {
    // Flatten the edge up against this button, unless we have explicit instructions not to.
    moreButtonProps.borderRadius = "0 2px 2px 0;";
  }

  return (
    <>
      <ButtonGroup
        variant="contained"
        color="primary"
        ref={anchorRef}
        disableElevation
        fullWidth={fullWidth}
        style={groupStyle}
        type="button"
        orientation={orientation}
      >
        {!actionsOnly ? (
          <ButtonComponent
            onClick={defaultOnClick}
            disabled={defaultButton.isDisabled}
            inline={inline}
            fullWidth={fullWidth}
            data-tour={defaultButton.dataTour || ""}
            title={defaultButton.tooltip || defaultButton.title}
            type={buttonType}
          >
            {defaultButton.icon && (
              <DefaultButtonIcon
                icon={defaultButton.icon}
                spin={defaultButton.spin}
              />
            )}{" "}
            {defaultButton.title}
          </ButtonComponent>
        ) : null}

        {showMore ? (
          <ContextMenuPopoverButton
            ButtonComponent={ButtonComponent}
            buttonProps={moreButtonProps}
            anchorRef={anchorRef}
            buttons={moreButtons}
            stickySetting={stickySetting}
            includeSearchSubNavThreshold={includeSearchSubNavThreshold}
            restrictHeightSubNav={restrictHeightSubNav}
            actionsOnly={actionsOnly}
            actionsOnlyLabel={actionsOnlyLabel}
          />
        ) : null}
      </ButtonGroup>
      {defaultHasSubMenu ? (
        <SubMenuPopover
          subNavItems={defaultButton.subNavItems}
          anchorEl={anchorRef.current}
          onClose={closeDefaultButtonMenu}
          open={isDefaultButtonMenuOpen}
          includeSearchSubNavThreshold={includeSearchSubNavThreshold}
          restrictHeightSubNav={restrictHeightSubNav}
        />
      ) : null}
    </>
  );
};

export default MultiButtonComponent;
