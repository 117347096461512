import React, { useCallback, useContext, useEffect, useState } from "react";

import { faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import {
  faBalanceScale,
  faBan,
  faClock,
  faEllipsisV,
  faExclamationTriangle,
  faPlus,
  faRedo,
  faSave,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { Grid, Popover } from "@material-ui/core";
import { sum } from "lodash/math";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";

import { deleteAllUnassignedScans, patchSaleLot, setSetting } from "actions";

import { IconTextButton, MultiButton } from "components/Button";
import { ButtonIcon } from "components/Button/FontAwesomeButton";
import { ConfirmDialog } from "components/ConfirmDialog";
import { Column } from "components/Layout";
import { MicroLotCard } from "components/LotCard/MicroLotCard";

import { SaleLotPermissions } from "constants/permissions";
import { PricingTypes } from "constants/pricingTypes";
import { Settings } from "constants/settings";

import IndicatorReadout from "containers/WeightIndicator";

import {
  calculateTotalPriceCents,
  calculateUnitPrice,
  formatWeightKg,
} from "lib";

import {
  getAverageWeightFormatted,
  getEstimatedAlternativeCostPerUnit,
  getLotPricingDetails,
  isSold,
} from "lib/saleLot";

import {
  getSaleLotById,
  getSaleLots,
  getSetting,
  selectIsConnectedWeighBridge,
} from "selectors";

import { useToggle } from "hooks";
import { useHasPermission } from "hooks/useHasPermission";

import {
  WeighBridgeSaleDispatchContext,
  WeighBridgeSaleStateContext,
} from "./WeighBridgeSaleContext";

const AccumulatorWorking = styled(Grid)`
  text-align: right;
`;

const SaveWeightActions = {
  SAVE_WEIGHT: "SAVE_WEIGHT",
  SAVE_WEIGHT_AND_CLEAR_EIDS: "SAVE_WEIGHT_AND_CLEAR_EIDS",
};

const MAX_WEIGHT_VISIBLE = 2;

export function useWeighBridgeWeightUpdater() {
  const { accumulatedWeights } = useContext(WeighBridgeSaleStateContext);
  const { clearAccumulatedWeights, updateRecentlyWeighedSaleLotIds } =
    useContext(WeighBridgeSaleDispatchContext);

  const saleLots = useSelector(getSaleLots);

  const dispatch = useDispatch();

  const totalWeight = sum(accumulatedWeights);

  return useCallback(
    (saleLotId, updateRecent = true, addToExisting = false) => {
      const saleLot = saleLots[saleLotId] || {};

      const { pricing_type_id: pricingTypeId } = saleLot;

      const updatedWeight =
        totalWeight + (addToExisting && saleLot.total_mass_grams) || 0;

      // updating the Sale Lot's total price is required when the weight is changed as is a component of the total price,
      // We are storing total_mass_grams and total_price_cents, instead of unit price and total_mass_grams
      let updatedTotalPriceCents;
      if (pricingTypeId === PricingTypes.PER_KILO) {
        const unitPrice = calculateUnitPrice(saleLot);
        updatedTotalPriceCents = calculateTotalPriceCents({
          unitPrice,
          total_mass_grams: updatedWeight,
          pricing_type_id: pricingTypeId,
        });
      }

      dispatch(
        patchSaleLot(
          {
            total_mass_grams: updatedWeight,
            total_price_cents: updatedTotalPriceCents,
            timeWeighed: new Date().toISOString(),
            id: saleLotId,
          },
          { changeReason: "Weight added on weighbridge" },
        ),
      );
      clearAccumulatedWeights();
      updateRecent && updateRecentlyWeighedSaleLotIds(saleLotId);
    },
    [
      totalWeight,
      clearAccumulatedWeights,
      dispatch,
      saleLots,
      updateRecentlyWeighedSaleLotIds,
    ],
  );
}

export function WeighBridgeWeighingComponent(props) {
  const {
    accumulatedWeights,
    indicatedWeight,
    isAccumulatedWeightsDirty,
    isAccumulatedWeightsValid,
    saleLotId,
  } = props;
  const {
    addAccumulatedWeight,
    clearAccumulatedWeights,
    removeAccumulatedWeight,
    updateIndicatedWeight,
  } = useContext(WeighBridgeSaleDispatchContext);

  const saleLot = useSelector(getSaleLotById(saleLotId));
  const isScaleConnected = useSelector(selectIsConnectedWeighBridge);
  const saveWeightAction =
    useSelector(getSetting(Settings.saveWeightDefaultAction)) ||
    SaveWeightActions.SAVE_WEIGHT;

  const [viewAllEl, setViewAllEl] = useState(null);
  const [isStable, setIsStable] = useState(null);
  const [shouldCaptureWhenStable, toggleShouldCaptureWhenStable] =
    useToggle(false);
  const updateSaleLotWeight = useWeighBridgeWeightUpdater();

  const [isConfirmReweighDialogOpen, toggleIsConfirmReweighDialogOpen] =
    useToggle();

  const onWeightChanged = useCallback(
    (weightGrams, isStable) => {
      updateIndicatedWeight(weightGrams);
      setIsStable(isStable);
      if (weightGrams > 0 && isStable && shouldCaptureWhenStable) {
        toggleShouldCaptureWhenStable();
        addAccumulatedWeight(weightGrams);
      }
    },
    [
      updateIndicatedWeight,
      setIsStable,
      toggleShouldCaptureWhenStable,
      shouldCaptureWhenStable,
      addAccumulatedWeight,
    ],
  );

  const isAllWeightListVisible = Boolean(
    viewAllEl && accumulatedWeights.length > MAX_WEIGHT_VISIBLE,
  );

  const accumulatedWeight = accumulatedWeights.reduce(
    (acc, weight) => acc + weight,
    0,
  );
  const accumulatedWeightCount = accumulatedWeights.length;

  const accumulatedWeightFormatted = formatWeightKg(accumulatedWeight);

  const hasExistingWeight =
    saleLot &&
    typeof saleLot.total_mass_grams === "number" &&
    saleLot.total_mass_grams > 0;

  const newAverageWeighFormatted =
    saleLot && accumulatedWeight
      ? getAverageWeightFormatted({
          quantity: saleLot.quantity,
          total_mass_grams: accumulatedWeight,
        })
      : "-";

  const existingWeightFormatted = hasExistingWeight
    ? formatWeightKg(saleLot.total_mass_grams)
    : "-";

  const weightAfterAddFormatted =
    saleLot && saleLot.total_mass_grams
      ? formatWeightKg(saleLot.total_mass_grams + accumulatedWeight)
      : "-";

  const showPrices = saleLotId && isSold(saleLot.buyer_id, saleLot.vendor_id);
  const saleLotPrices = getLotPricingDetails(saleLot);

  const priceEstimates = getEstimatedAlternativeCostPerUnit(saleLotPrices);
  const estimatedFormattedPrice =
    showPrices && priceEstimates
      ? `${priceEstimates.priceUnits}: ~${priceEstimates.price}`
      : "";

  const actualFormattedPrice = showPrices
    ? `${saleLotPrices.priceUnits}: ${saleLotPrices.price}`
    : "";

  useEffect(() => {
    viewAllEl &&
      accumulatedWeightCount <= MAX_WEIGHT_VISIBLE &&
      setViewAllEl(null);
  }, [viewAllEl, accumulatedWeightCount]);

  const dispatch = useDispatch();

  function onClickClear() {
    clearAccumulatedWeights();
  }

  function onClickWeigh() {
    addAccumulatedWeight(indicatedWeight);
  }

  function onClickViewAll(event) {
    setViewAllEl(isAllWeightListVisible ? null : event.target);
  }

  function onCloseAllWeightList() {
    setViewAllEl(null);
  }

  function onClickReWeigh() {
    toggleIsConfirmReweighDialogOpen();
  }

  function onClickConfirmReweigh() {
    dispatch(
      patchSaleLot(
        {
          id: saleLot.id,
          total_mass_grams: 0,
          timeWeighed: new Date().toISOString(),
          total_price_cents: calculateTotalPriceCents({
            unitPrice: calculateUnitPrice({
              total_price_cents: saleLot.total_price_cents,
              total_mass_grams: saleLot.total_mass_grams,
              pricing_type_id: saleLot.pricing_type_id,
              quantity: saleLot.quantity,
            }),
            total_mass_grams: 0,
            pricing_type_id: saleLot.pricing_type_id,
            quantity: saleLot.quantity,
          }),
        },
        { changeReason: "Reweigh from weighbridge" },
      ),
    );
    toggleIsConfirmReweighDialogOpen();
  }

  function onClickSaveWeight() {
    updateSaleLotWeight(saleLotId);
    dispatch(
      setSetting(
        Settings.saveWeightDefaultAction,
        SaveWeightActions.SAVE_WEIGHT,
      ),
    );
  }

  function onClickSaveWeightAndClearEids() {
    updateSaleLotWeight(saleLotId);
    dispatch(deleteAllUnassignedScans());
    dispatch(
      setSetting(
        Settings.saveWeightDefaultAction,
        SaveWeightActions.SAVE_WEIGHT_AND_CLEAR_EIDS,
      ),
    );
  }

  function onClickAddToExistingWeight() {
    updateSaleLotWeight(saleLotId, true, true);
  }

  const isBaseCaptureWeightDisabled =
    !isScaleConnected || indicatedWeight <= 0 || !isStable;

  const isWeightAlreadyCaptured = hasExistingWeight || accumulatedWeight;

  const isCaptureAnotherDisabled =
    isBaseCaptureWeightDisabled || !accumulatedWeight;

  const isCaptureDisabled = isBaseCaptureWeightDisabled || accumulatedWeight;

  const isCaptureUnsteadyDisabled =
    !isScaleConnected || indicatedWeight <= 0 || isStable;

  const isCaptureOnStableDisabled =
    !isScaleConnected || shouldCaptureWhenStable || hasExistingWeight;

  const isCancelCaptureOnStableDisabled =
    !isScaleConnected || !shouldCaptureWhenStable;

  const isClearDisabled = accumulatedWeights.length === 0;

  const isReweighDisabled = !(saleLot && saleLot.total_mass_grams > 0);

  const reweighTooltip = isReweighDisabled
    ? "You cannot reweigh a Sale Lot without any recorded weight."
    : "Clear the weight from the selected Sale Lot";

  let saveTooltip = `Save ${accumulatedWeightFormatted} to the selected Sale Lot`;
  if (!saleLotId) {
    saveTooltip = "You must select a Sale Lot before you can record weight";
  } else if (!isAccumulatedWeightsDirty || !isAccumulatedWeightsValid) {
    saveTooltip =
      "You must capture weight before you can record it against a Sale Lot";
  }

  let captureTooltip = "Add weight displayed now.";
  if (isCaptureDisabled) {
    if (!isScaleConnected) {
      captureTooltip = "No scales are connected.";
    } else if (hasExistingWeight) {
      captureTooltip =
        "The selected Sale Lot already has a weight recorded.\nUse the Clear, Reweigh or Capture Another weight to record weights.";
    } else if (indicatedWeight <= 0) {
      captureTooltip = "You can only capture weights greater than 0 kg.";
    } else if (!isStable) {
      captureTooltip = "The weight indicated is not stable.";
    }
  }

  let captureNextStableTooltip =
    "When the weight becomes stable automatically capture it.";
  if (isCaptureOnStableDisabled) {
    if (!isScaleConnected) {
      captureNextStableTooltip = "No scales are connected.";
    } else if (indicatedWeight <= 0) {
      captureNextStableTooltip =
        "You can only capture weights greater than 0 kg.";
    } else if (isStable) {
      captureNextStableTooltip =
        "The weight indicated is stable.\nStable weight can be captured manually using the Capture buttons.";
    } else if (shouldCaptureWhenStable) {
      captureNextStableTooltip = "Auto capture is already running.";
    } else if (accumulatedWeights.length > 0) {
      captureNextStableTooltip =
        "There is already a weight captured.\nAdditional multi-weigh weights must be capture manually using the Capture buttons.";
    } else if (hasExistingWeight) {
      captureNextStableTooltip =
        "The selected Sale Lot already has a weight recorded.\nUse the Clear, Reweigh or Capture Another weight to record weights.";
    }
  }

  let captureAnotherTooltip = "Add weight displayed now.";
  if (isCaptureAnotherDisabled) {
    if (!isScaleConnected) {
      captureAnotherTooltip = "No scales are connected.";
    } else if (indicatedWeight <= 0) {
      captureAnotherTooltip = "You can only capture weights greater than 0 kg.";
    } else if (!isStable) {
      captureAnotherTooltip = "The weight indicated is not stable.";
    }
  }

  const isCaptureDefault =
    !shouldCaptureWhenStable && isStable && indicatedWeight > 0;
  const isCaptureWhenStableDefault =
    !shouldCaptureWhenStable && (!isStable || indicatedWeight > 0);
  const isCancelCaptureWhenStableDefault = shouldCaptureWhenStable;

  const weighButtons = [
    isWeightAlreadyCaptured && {
      title: "Existing Weight Found",
      dataTour: "existingWeightFound",
      isDisabled: true,
      default: true,
      icon: faBalanceScale,
      key: "existing-weight-found",
    },
    !accumulatedWeight && {
      title: `Capture ${formatWeightKg(indicatedWeight)}`,
      dataTour: `captureWeight${isCaptureDisabled ? ".disabled" : ""}`,
      isDisabled: isCaptureDisabled,
      onClick: onClickWeigh,
      default: isCaptureDefault,
      icon: faPlus,
      tooltip: captureTooltip,
      key: "capture-weight",
    },
    accumulatedWeight && {
      title: `Capture Another ${formatWeightKg(indicatedWeight)}`,
      dataTour: `captureAnotherWeight${
        isCaptureAnotherDisabled ? ".disabled" : ""
      }`,
      isDisabled: isCaptureAnotherDisabled,
      onClick: onClickWeigh,
      default: false,
      icon: faPlus,
      tooltip: captureAnotherTooltip,
      key: "capture-another-weight",
    },
    {
      title: "Capture Weight When Stable",
      dataTour: "captureWeightWhenStable",
      isDisabled: isCaptureOnStableDisabled,
      onClick: toggleShouldCaptureWhenStable,
      default: isCaptureWhenStableDefault,
      icon: faClock,
      tooltip: captureNextStableTooltip,
      key: "capture-weight-when-stable",
    },
    {
      title: "Cancel Capture When Stable",
      dataTour: "cancelCaptureWhenStable",
      isDisabled: isCancelCaptureOnStableDisabled,
      onClick: toggleShouldCaptureWhenStable,
      default: isCancelCaptureWhenStableDefault,
      icon: faTimesCircle,
      key: "cancel-capture-when-stable",
    },

    {
      title: `Capture ${accumulatedWeight ? "Another" : ""} Unsteady Weight`,
      dataTour: `capture${accumulatedWeight ? "Another" : ""}UnsteadyWeight${
        isCaptureUnsteadyDisabled ? ".disabled" : ""
      }`,
      isDisabled: isCaptureUnsteadyDisabled,
      onClick: onClickWeigh,
      default: false,
      icon: faPlus,
      key: "capture-unsteady-weight",
    },

    {
      title: "Clear Weight",
      dataTour: "clearWeight",
      isDisabled: isClearDisabled,
      onClick: onClickClear,
      default: false,
      icon: faBan,
      key: "clear-weight",
    },
    {
      title: "Reweigh",
      dataTour: "reweigh",
      isDisabled: isReweighDisabled,
      onClick: onClickReWeigh,
      default: false,
      icon: faRedo,
      tooltip: reweighTooltip,
      key: "reweigh",
    },
  ].filter(Boolean);

  const hasChangeSaleLotPermission = useHasPermission(
    getSaleLotById(saleLotId),
    SaleLotPermissions.update,
  );

  const isAllSaveDisabled =
    !hasChangeSaleLotPermission ||
    !isAccumulatedWeightsDirty ||
    !isAccumulatedWeightsValid ||
    !saleLotId;
  const isSaveDisabled = isAllSaveDisabled || hasExistingWeight;
  const allowOverwrite = hasExistingWeight && accumulatedWeight;
  const isOverwriteDisabled = isAllSaveDisabled || !allowOverwrite;

  const saveButtons = [
    {
      title: `Save ${formatWeightKg(accumulatedWeight)}`,
      isDisabled: isSaveDisabled,
      onClick: onClickSaveWeight,
      default: saveWeightAction === SaveWeightActions.SAVE_WEIGHT,
      icon: faSave,
      tooltip: saveTooltip,
      dataTour: "saveWeight",
      key: "save-weight",
    },
    {
      title: `Save ${formatWeightKg(accumulatedWeight)} and Clear EIDs`,
      isDisabled: isSaveDisabled,
      onClick: onClickSaveWeightAndClearEids,
      default:
        saveWeightAction === SaveWeightActions.SAVE_WEIGHT_AND_CLEAR_EIDS,
      icon: faSave,
      tooltip: saveTooltip,
      dataTour: "saveWeightAndClearEIDs",
      key: "save-weight-and-clear-eids",
    },
    {
      title: allowOverwrite
        ? `Overwrite ${existingWeightFormatted} with ${formatWeightKg(
            accumulatedWeight,
          )}`
        : "Overwrite existing weight",
      isDisabled: isOverwriteDisabled,
      onClick: onClickSaveWeight,
      default: false,
      icon: faExclamationTriangle,
      tooltip: saveTooltip,
      dataTour: "overwriteWeight",
      key: "overwrite-existing-weight",
    },
    {
      title: allowOverwrite
        ? `Add ${existingWeightFormatted} to ${formatWeightKg(
            accumulatedWeight,
          )} = ${weightAfterAddFormatted}`
        : "Add to existing weight.",
      isDisabled: isOverwriteDisabled,
      onClick: onClickAddToExistingWeight,
      default: false,
      icon: faExclamationTriangle,
      tooltip: saveTooltip,
      dataTour: "addToExistingWeight",
      key: "add-to-existing-weight",
    },
  ];

  return (
    <Grid item container spacing={1}>
      <Grid
        item
        sm={12}
        md={12}
        container
        alignContent="center"
        justifyContent="space-around"
      >
        <MicroLotCard saleLotId={saleLotId} />
      </Grid>

      <Grid
        item
        sm={6}
        md={3}
        container
        alignContent="center"
        justifyContent="center"
      >
        <Column>
          <IndicatorReadout onWeightChanged={onWeightChanged} />
        </Column>
      </Grid>
      <Grid
        item
        sm={6}
        md={3}
        container
        alignContent="center"
        justifyContent="center"
      >
        <MultiButton buttons={weighButtons} fullWidth fullHeight />
      </Grid>

      <Grid
        item
        sm={6}
        md={4}
        container
        alignContent="center"
        justifyContent="center"
      >
        <Grid item xs={4} container justifyContent="center">
          <h4 className="text-center">
            Total: {accumulatedWeightFormatted}
            <br />
            (Avg: {newAverageWeighFormatted})
          </h4>
        </Grid>
        <Grid item container xs={5} justifyContent="center">
          <h4 className="text-center">
            {actualFormattedPrice}
            <br />
            {estimatedFormattedPrice}
          </h4>
        </Grid>
        <Grid
          xs={3}
          container
          item
          direction="column"
          alignItems="center"
          justifyContent="flex-start"
        >
          <AccumulatorWorking>
            {accumulatedWeights
              .slice(-MAX_WEIGHT_VISIBLE)
              .map((accumulatedWeight, index) => {
                function onClickRemoveWeight() {
                  const offsetIndex =
                    accumulatedWeightCount > MAX_WEIGHT_VISIBLE
                      ? index + accumulatedWeightCount - MAX_WEIGHT_VISIBLE
                      : index;
                  removeAccumulatedWeight(offsetIndex);
                }

                return (
                  <div key={index} data-tour="AccumulatedWeight">
                    {index > 0 || accumulatedWeightCount > MAX_WEIGHT_VISIBLE
                      ? "+"
                      : null}
                    {formatWeightKg(accumulatedWeight, false)}
                    &nbsp;
                    <button
                      type="button"
                      title={`Remove this weight (${formatWeightKg(
                        accumulatedWeight,
                        true,
                        false,
                      )})`}
                      onClick={onClickRemoveWeight}
                    >
                      <ButtonIcon icon={faTrash} />
                    </button>
                  </div>
                );
              })}
            {accumulatedWeightCount > MAX_WEIGHT_VISIBLE && (
              <IconTextButton icon={faEllipsisV} onClick={onClickViewAll}>
                View All ({accumulatedWeightCount})
              </IconTextButton>
            )}
            <Popover
              open={isAllWeightListVisible}
              anchorEl={viewAllEl}
              onClose={onCloseAllWeightList}
            >
              <AccumulatedWeightTable />
            </Popover>
          </AccumulatorWorking>
        </Grid>
      </Grid>

      <Grid
        item
        sm={6}
        md={2}
        container
        alignContent="center"
        justifyContent="center"
      >
        <MultiButton buttons={saveButtons} fullWidth fullHeight />
      </Grid>
      <ConfirmDialog
        title="Confirm Reweigh"
        isOpen={isConfirmReweighDialogOpen}
        onCancel={toggleIsConfirmReweighDialogOpen}
        buttonMessage="Confirm"
        message="Confirm you want to reweigh.  This will remove the previously entered weight from the Sale Lot"
        onDelete={onClickConfirmReweigh}
      />
    </Grid>
  );
}

WeighBridgeWeighingComponent.propTypes = {
  accumulatedWeights: PropTypes.arrayOf(PropTypes.number).isRequired,
  indicatedWeight: PropTypes.number.isRequired,
  saleLotId: PropTypes.string,
};

// used to avoid a re-render when the state object has changed, but the values this component uses haven't changed.

export default function StateAdapter() {
  const {
    accumulatedWeights,
    indicatedWeight,
    isAccumulatedWeightsDirty,
    isAccumulatedWeightsValid,
    selectedSaleLotId,
  } = useContext(WeighBridgeSaleStateContext);
  return (
    <WeighBridgeWeighingComponent
      accumulatedWeights={accumulatedWeights}
      isAccumulatedWeightsDirty={isAccumulatedWeightsDirty}
      isAccumulatedWeightsValid={isAccumulatedWeightsValid}
      indicatedWeight={indicatedWeight}
      saleLotId={selectedSaleLotId}
    />
  );
}

function AccumulatedWeightTable() {
  const { accumulatedWeights } = useContext(WeighBridgeSaleStateContext);
  const { removeAccumulatedWeight } = useContext(
    WeighBridgeSaleDispatchContext,
  );
  return (
    <table cellPadding={6}>
      {accumulatedWeights.map((accumulatedWeight, index) => {
        function onClickRemoveWeight() {
          removeAccumulatedWeight(index);
        }

        return (
          <tr>
            <td>{formatWeightKg(accumulatedWeight, false)}</td>
            <td>
              <button
                type="button"
                title={`Remove this weight (${formatWeightKg(
                  accumulatedWeight,
                  true,
                  false,
                )})`}
                onClick={onClickRemoveWeight}
              >
                <ButtonIcon icon={faTrash} />
              </button>
            </td>
          </tr>
        );
      })}
    </table>
  );
}
