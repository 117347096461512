import * as Yup from "yup";

import { SaleTypes } from "constants/sale";

import { validatePIC } from "lib/PICValidator";
import { validatePercentage } from "lib/validators";

// Date() use this format: mm/dd/yyyy
const MINIMUM_DATE = "01/01/1900";
const buyerRequired = Yup.mixed()
  .notOneOf(["-1", -1], "The buyer is required")
  .required("The buyer is required");
const saleTypeRequired = Yup.string().required("The sale type is required");
const saleyardIdRequired = Yup.number()
  .positive("The saleyard is required")
  .required("The saleyard is required");
const futureDateRequired = Yup.date()
  .typeError("Please use dd/mm/yyyy format")
  .min(MINIMUM_DATE, `Must be after ${MINIMUM_DATE}`)
  .required("The date is required");
const startDateTimeValidation = Yup.date().typeError(
  "Please use dd/mm/yyyy format",
);
const endDateTimeValidation = Yup.date()
  .typeError("Please use dd/mm/yyyy format")
  .when(
    ["start_datetime", "date"],
    (startDate, date, yup) =>
      (startDate &&
        yup.min(startDate, "End date cannot be before start date")) ||
      (!startDate &&
        date &&
        yup.min(date, "End date cannot be before sale date")),
  )
  .nullable();
const pricingTypeRequired = Yup.number()
  .positive("The pricing type is required")
  .required("The pricing type is required");
const speciesRequired = Yup.number()
  .positive("The species is required")
  .required("The species is required");
const defaultPropertyRequired = Yup.mixed()
  .notOneOf(["-1", -1], "The Buyer PIC is required")
  .required("The Buyer PIC is required");
const roundsRequired = Yup.array().min(1, "Rounds are required");
const saleSubTypeRequired = Yup.string("Sale Sub Type is required")
  .nullable()
  .required("Sale Sub Type is required");

const defaultVendorRequired = Yup.mixed()
  .notOneOf(["-1", -1], "The default vendor is required")
  .required("The default vendor is required");

const locationPropertyTest = Yup.object()
  .shape({
    PIC: Yup.string()
      .nullable()
      .test("Invalid PIC", "PIC must be valid if entered", value =>
        value ? validatePIC(value) : true,
      ),
  })
  .nullable();

export const AuctionSaleSchema = subTypesExist =>
  Yup.object().shape({
    sale_type: saleTypeRequired,
    saleyard_id: saleyardIdRequired,
    start_datetime: startDateTimeValidation,
    end_datetime: endDateTimeValidation,
    date: futureDateRequired,
    pricing_type_id: pricingTypeRequired,
    species_id: speciesRequired,
    rounds: roundsRequired,
    sale_sub_type_id: subTypesExist ? saleSubTypeRequired : null,
  });

export const OnFarmAuctionSchema = subTypesExist =>
  Yup.object().shape({
    sale_type: saleTypeRequired,
    saleyard_id: saleyardIdRequired,
    start_datetime: startDateTimeValidation,
    end_datetime: endDateTimeValidation,
    date: futureDateRequired,
    pricing_type_id: pricingTypeRequired,
    species_id: speciesRequired,
    rounds: roundsRequired,
    sale_sub_type_id: subTypesExist ? saleSubTypeRequired : null,
    location_property_id: locationPropertyTest,
  });

export const BobbyCalfSchema = Yup.object().shape({
  saleyard_id: saleyardIdRequired,
  date: futureDateRequired,
  default_buyer_id: Yup.string(),
  default_property_id: defaultPropertyRequired,
});

const HooksSchema = subTypesExist =>
  Yup.object().shape({
    sale_type: saleTypeRequired,
    date: futureDateRequired,
    species_id: speciesRequired,
    pricing_type_id: pricingTypeRequired,
    sale_sub_type_id: subTypesExist ? saleSubTypeRequired : null,
    default_buyer_id: buyerRequired,
  });

const PaddockSchema = subTypesExist =>
  Yup.object().shape({
    sale_type: saleTypeRequired,
    date: futureDateRequired,
    species_id: speciesRequired,
    pricing_type_id: pricingTypeRequired,
    default_vendor_id: defaultVendorRequired,
    sale_sub_type_id: subTypesExist ? saleSubTypeRequired : null,
  });

export const PrivateWeighSchema = subTypesExist =>
  Yup.object().shape({
    sale_type: saleTypeRequired,
    saleyard_id: saleyardIdRequired,
    date: futureDateRequired,
    pricing_type_id: pricingTypeRequired,
    species_id: speciesRequired,
    sale_sub_type_id: subTypesExist ? saleSubTypeRequired : null,
  });

const ClearingSchema = subTypesExist =>
  Yup.object().shape({
    sale_type: saleTypeRequired,
    date: futureDateRequired,
    start_datetime: startDateTimeValidation,
    end_datetime: endDateTimeValidation,
    default_buyers_premium: validatePercentage(9, 4)
      .nullable()
      .required("The default buyers premium is required"),
    default_listing_fee_cents: Yup.number()
      .nullable()
      .required("The default listing fee is required"),
    default_vendor_commission: validatePercentage(9, 6).required(
      "The default vendor commission is required",
    ),
    deployment_sales: Yup.array().of(
      Yup.object().shape({
        auctions_plus_url: Yup.string()
          .url("Please enter a valid URL without spaces")
          .nullable(),
      }),
    ),
    sale_sub_type_id: subTypesExist ? saleSubTypeRequired : null,
  });

export const SundrySaleSchema = subTypesExist =>
  Yup.object().shape({
    sale_type: saleTypeRequired,
    saleyard_id: saleyardIdRequired,
    date: futureDateRequired,
    sale_sub_type_id: subTypesExist ? saleSubTypeRequired : null,
  });

export const validationSchema = subTypesExist =>
  Yup.lazy(values => {
    switch (values.sale_type) {
      case SaleTypes.OVER_HOOKS: {
        return HooksSchema(subTypesExist);
      }
      case SaleTypes.PADDOCK: {
        return PaddockSchema(subTypesExist);
      }
      case SaleTypes.CLEARING: {
        return ClearingSchema(subTypesExist);
      }
      case SaleTypes.BOBBYCALF: {
        return BobbyCalfSchema;
      }
      case SaleTypes.PRIVATE_WEIGH: {
        return PrivateWeighSchema(subTypesExist);
      }
      case SaleTypes.SUNDRY_SALE: {
        return SundrySaleSchema(subTypesExist);
      }
      case SaleTypes.ON_FARM_AUCTION: {
        return OnFarmAuctionSchema(subTypesExist);
      }
      default:
        return AuctionSaleSchema(subTypesExist);
    }
  });
