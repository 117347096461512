import React from "react";

import { useFormikContext } from "formik";
import { get } from "lodash";
import { useSelector } from "react-redux";

import { getNextEmptyPenNumber } from "selectors";

import { Input } from "./Input";

export const AutoIncrementingPenInput = props => {
  // If the permission check that is passed through is falsey, this will function as a normal input.
  const { name, enableAutoIncrement = false } = props;
  const nextPenNumber = useSelector(getNextEmptyPenNumber);
  const { setFieldValue, touched, initialValues } = useFormikContext();
  const isTouched = get(touched, name);
  const hasinitialValue = Boolean(get(initialValues, name));
  React.useEffect(() => {
    if (!isTouched && !hasinitialValue && enableAutoIncrement) {
      setFieldValue(name, nextPenNumber);
    }
  }, [
    name,
    nextPenNumber,
    isTouched,
    setFieldValue,
    enableAutoIncrement,
    hasinitialValue,
  ]);

  return <Input {...props} />;
};
