import React, { useState } from "react";

import { faSpinnerThird } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DialogActions } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useDispatch, useSelector } from "react-redux";

import { openDispatchingModal } from "actions";

import { updateNLISScanDetails } from "actions/offline";

import { BulkCreateSaleLotsModal } from "components/BulkCreateSaleLotsModal";
import { AddButton } from "components/Button/CollapseInlineButton";
import { DeleteScan } from "components/DeleteScan";
import EIDListDetailed from "components/EIDList/EIDListDetailed";
import {
  Button,
  DeleteButton,
  LabelArea,
  SecondaryButton,
} from "components/Form";
import { Column, Row } from "components/Layout";
import { Dialog, DialogContent, DialogTitle } from "components/MaterialDialog";
import { SelectConsignmentForEids } from "components/SelectConsignmentForEids";
import { SelectReceivalLotForEids } from "components/SelectReceivalLotForEids";
import { SelectSaleLotForEids } from "components/SelectSaleLotForEids";

import { ModalTypes } from "constants/navigation";
import { NLISFileTypes, TRANSFER_STATUS } from "constants/nlis";
import { SaleyardPermissions } from "constants/permissions";

import { getSettingsRoute } from "lib/navigation";
import { pluralize } from "lib/pluralize";

import { getHasEnteredNlisCreds, getScanByEid } from "selectors";

import {
  useHasEditWeightOnEIDPermission,
  useHasSaleyardPermission,
} from "hooks";

import { EditScanWeight } from "./EditScanWeightSection";

const EditScansSection = {
  ALLOCATE: "ALLOCATE",
  DELETE: "DELETE",
  NONE: "NONE",
  UPDATE_SALE_LOT: "UPDATE_SALE_LOT",
  UPDATE_RECEIVAL_LOT: "UPDATE_RECEIVAL_LOT",
  UPDATE_WEIGHT: "UPDATE_WEIGHT",
  UPDATE_CONSIGNMENT: "UPDATE_CONSIGNMENT",
};

const SelectActionSection = ({
  eids,
  selectAllocate,
  selectSaleLot,
  selectReceivalLot,
  showDelete,
  onClose,
  showUpdateWeight,
  selectConsignment,
}) => {
  const dispatch = useDispatch();
  const hasForceTakePermission = useHasSaleyardPermission(
    SaleyardPermissions.canForceTake,
  );
  const hasForceSellPermission = useHasSaleyardPermission(
    SaleyardPermissions.canForceSell,
  );
  const hasBulkUpdateScanConsignment = useHasSaleyardPermission(
    SaleyardPermissions.canBulkUpdateSaleLotConsignment,
  );

  // Give the user a feeling of work... and stop them double clicking!
  const [performingNLISLookup, setPerformingNLISLookup] = useState(false);

  const handleUpdateNLISDetails = () => {
    setPerformingNLISLookup(true);
    setTimeout(() => {
      setPerformingNLISLookup(false);
    }, 3000);
    dispatch(
      updateNLISScanDetails(
        eids.map(eid => ({
          EID: eid,
        })),
      ),
    );
  };

  function onClickForceTake() {
    dispatch(
      openDispatchingModal(ModalTypes.ForceTransfer, window.location.hash, {
        transferType: NLISFileTypes.NLISTakeFile,
        eids,
      }),
    );
  }
  function onClickForceSell() {
    dispatch(
      openDispatchingModal(ModalTypes.ForceTransfer, window.location.hash, {
        transferType: NLISFileTypes.NLISSellFile,
        eids,
      }),
    );
  }

  const hasNLISDetails = useSelector(getHasEnteredNlisCreds);
  const hasReceivalLotPermission = useHasSaleyardPermission(
    SaleyardPermissions.featureReceivalLots,
  );

  const hasEditWeightOnEIDPermission = useHasEditWeightOnEIDPermission();

  const showRepenScans = hasEditWeightOnEIDPermission;

  const showUpdateReceivalLot = hasReceivalLotPermission;

  const isNlisActionsDisabled = performingNLISLookup || !hasNLISDetails;

  return (
    <>
      <DialogContent minHeight="20vh" shrink={1} dividers>
        <Column full>
          <Row justifyBetween alignCenter>
            <LabelArea>NLIS</LabelArea>
            {!hasNLISDetails && (
              <AddButton to={getSettingsRoute()}>
                Add NLIS Credentials
              </AddButton>
            )}
          </Row>
          <Grid container spacing={2}>
            <Grid item container xs={3}>
              <SecondaryButton
                title="Refresh NLIS Information"
                type="button"
                disabled={isNlisActionsDisabled}
                onClick={handleUpdateNLISDetails}
              >
                Fetch NLIS Information{" "}
                {performingNLISLookup && (
                  <FontAwesomeIcon spin icon={faSpinnerThird} />
                )}
              </SecondaryButton>
            </Grid>
            {hasForceTakePermission ? (
              <Grid item container xs={3}>
                <SecondaryButton
                  title="Force Take"
                  type="button"
                  disabled={isNlisActionsDisabled}
                  onClick={onClickForceTake}
                >
                  Force Take
                </SecondaryButton>
              </Grid>
            ) : null}
            {hasForceSellPermission ? (
              <Grid item container xs={3}>
                <SecondaryButton
                  title="Force Sell"
                  type="button"
                  disabled={isNlisActionsDisabled}
                  onClick={onClickForceSell}
                >
                  Force Sell
                </SecondaryButton>
              </Grid>
            ) : null}
          </Grid>

          <Row>
            <LabelArea>Edit</LabelArea>
          </Row>
          <Grid container spacing={2}>
            <Grid item container xs={3}>
              <SecondaryButton
                data-tour="updateSaleLot"
                title="Update Sale Lot"
                type="button"
                onClick={selectSaleLot}
              >
                Update Sale Lot
              </SecondaryButton>
            </Grid>
            {showRepenScans && (
              <Grid item container xs={3}>
                <SecondaryButton
                  data-tour="rePenScans"
                  title="Re-Pen Scans"
                  type="button"
                  onClick={selectAllocate}
                >
                  Re-Pen Scans
                </SecondaryButton>
              </Grid>
            )}
            {showUpdateReceivalLot && (
              <Grid item container xs={3}>
                <SecondaryButton
                  data-tour="updateReceivalLot"
                  title="Update Receival Lot"
                  type="button"
                  onClick={selectReceivalLot}
                >
                  Update Receival Lot
                </SecondaryButton>
              </Grid>
            )}
            {hasEditWeightOnEIDPermission && (
              <Grid item container xs={3}>
                <SecondaryButton
                  data-tour="updateScanWeight"
                  title="Update Scan weight (Only available on a single scan)"
                  type="button"
                  onClick={showUpdateWeight}
                >
                  Edit Weight
                </SecondaryButton>
              </Grid>
            )}
            {hasBulkUpdateScanConsignment && (
              <Grid item container xs={3}>
                <SecondaryButton
                  data-tour="bulkUpdateConsigment"
                  title="Update Consignment"
                  type="button"
                  onClick={selectConsignment}
                >
                  Update Consignment
                </SecondaryButton>
              </Grid>
            )}
          </Grid>
          {typeof showDelete === "function" && (
            <>
              <Row>
                <LabelArea>Delete</LabelArea>
              </Row>
              <Grid container spacing={2}>
                <Grid item container xs={3}>
                  <DeleteButton
                    title="Delete Scans"
                    type="button"
                    onClick={showDelete}
                  >
                    Delete EID
                  </DeleteButton>
                </Grid>
              </Grid>
            </>
          )}
        </Column>
      </DialogContent>

      <DialogActions shrink={0}>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </>
  );
};

export const EditScansModal = ({ closeSelf, eids }) => {
  const [activeSection, setActiveSection] = useState(EditScansSection.NONE);

  const singleEid = eids.length === 1 ? eids[0] : null;
  const singleScan = useSelector(getScanByEid(singleEid));

  const allowDelete =
    singleScan &&
    singleScan.nlis_sell_status === TRANSFER_STATUS.UNSUBMITTED &&
    singleScan.nlis_take_status === TRANSFER_STATUS.UNSUBMITTED;

  const showSelectSaleLot = () => {
    setActiveSection(EditScansSection.UPDATE_SALE_LOT);
  };

  const showSelectReceivalLot = () => {
    setActiveSection(EditScansSection.UPDATE_RECEIVAL_LOT);
  };

  const showSelectAction = () => {
    setActiveSection(EditScansSection.NONE);
  };
  const showSelectAllocate = () => {
    setActiveSection(EditScansSection.ALLOCATE);
  };

  const showUpdateWeight = () => {
    setActiveSection(EditScansSection.UPDATE_WEIGHT);
  };

  const showSelectConsignment = () => {
    setActiveSection(EditScansSection.UPDATE_CONSIGNMENT);
  };

  const showDeleteAction = allowDelete
    ? () => {
        setActiveSection(EditScansSection.DELETE);
      }
    : null;

  const onClose = () => {
    closeSelf();
  };

  return (
    <Dialog open onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle onClose={onClose}>
        Editing {eids.length} {pluralize("Scan", eids.length)}
      </DialogTitle>
      <DialogContent
        minHeight={
          [EditScansSection.DELETE, EditScansSection.UPDATE_WEIGHT].includes(
            activeSection,
          )
            ? "10vh"
            : "20vh"
        }
        shrink={1}
      >
        <EIDListDetailed eids={eids} largeView={eids.length > 1} />
      </DialogContent>
      {activeSection === EditScansSection.NONE && (
        <SelectActionSection
          eids={eids}
          selectSaleLot={showSelectSaleLot}
          selectReceivalLot={showSelectReceivalLot}
          selectAllocate={showSelectAllocate}
          showDelete={showDeleteAction}
          onClose={onClose}
          showUpdateWeight={showUpdateWeight}
          selectConsignment={showSelectConsignment}
        />
      )}
      {activeSection === EditScansSection.UPDATE_SALE_LOT && (
        <SelectSaleLotForEids
          eids={eids}
          onBack={showSelectAction}
          onClose={onClose}
        />
      )}
      {activeSection === EditScansSection.UPDATE_WEIGHT && (
        <EditScanWeight onBack={showSelectAction} eids={eids} />
      )}
      {activeSection === EditScansSection.UPDATE_RECEIVAL_LOT && (
        <SelectReceivalLotForEids
          eids={eids}
          onBack={showSelectAction}
          onClose={onClose}
        />
      )}
      {activeSection === EditScansSection.ALLOCATE && (
        <BulkCreateSaleLotsModal
          isOpen
          eids={eids}
          closeSelf={showSelectAction}
        />
      )}
      {activeSection === EditScansSection.UPDATE_CONSIGNMENT && (
        <SelectConsignmentForEids
          isOpen
          eids={eids}
          onClose={showSelectAction}
        />
      )}
      {activeSection === EditScansSection.DELETE && (
        <DeleteScan
          isOpen
          scan={singleScan}
          closeSelf={showSelectAction}
          onBack={showSelectAction}
          onClose={onClose}
        />
      )}
    </Dialog>
  );
};
