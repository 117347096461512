/*
Show a minimum of 2 and a maximum of the 9 fractional digits.  Normally we expect unit_price to be in dollars (2 decimals), but
do store up to 9 decimals.
 */
export const ledgerEntryPriceFormatter = new Intl.NumberFormat("en-AU", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 9,
  useGrouping: false,
});

/*
  Show a minimum of 0 and a maximum of the 9 fractional digits.  Normally we expect quantity to be a whole number, but
  do store up to 9 decimals.
   */
export const ledgerEntryQuantityFormatter = new Intl.NumberFormat("en-AU", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 9,
  useGrouping: false,
});
